import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, Divider, Icon, message, Popconfirm, Row, Select } from 'antd';


import allCountries from '../assets/allCountries'
import { connectInfiniteHits, connectRefinementList } from 'react-instantsearch-dom';
import EditDrawer from "./EditDrawer";
import { CustomRefinementList } from "../pages/AllUsers";
import { afFilter, countryFilter, interestFilter, resetFilter } from "../actions/filters"
import { connect } from "react-redux";
import { deleteUser } from "../api";
const { Option } = Select;

function Hit({ hits, refineNext, hasMore, resetFilter, refinePrevious }) {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])
	// const [hasMore, setHasMore] = useState(true)
	const [lastKey, setLastKey] = useState()
	const [editMode, setEditMode] = useState(false)

	const [deletedUsers, setDeletedUsers] = useState([])

	const handleInfiniteOnLoad = () => {
		if (hasMore) {
			refineNext()
		}
	};

	const getHitsNotDeleted = (H, D) => {
		const result = []

		for (let index = 0; index < H.length; index++) {
			const element = H[index]
			if (!D.find(uid => uid == element.objectID))
				result.push(element)
		}

		return result
	}

	const filteredHits = getHitsNotDeleted(hits, deletedUsers)



	return (
		<div className="demo-infinite-container">
			<InfiniteScroll
				initialLoad={false}
				pageStart={0}
				loadMore={(page) => {
					return handleInfiniteOnLoad()
				}}
				hasMore={hasMore}
				useWindow={false}
			>
				<Row type="flex" justify="space-between">
					<Col span={3}>
						<div>index</div>
					</Col>
					<Col span={4}>
						<CustomRefinementListAF
							attribute="african_country.value"
							operator="and"
						/>
					</Col>
					<Col span={3}>
						<div><CustomRefinementListCountry
							attribute="country"
							operator="and"

						/></div>
					</Col>
					<Col span={3}>
						<div>
							<CustomRefinementListInterest
								attribute="interests.text"
								operator="and"
							/>
						</div>
					</Col>
					<Col span={3}>
						<div>interest 2</div>
					</Col>
					<Col span={3}>
						<div>interest 3</div>
					</Col>
					<Col span={4}>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>Actions
	
							<div
								onClick={() => {
									resetFilter(Date.now().toString())
									refinePrevious()
								}}
							>
								<Button>
									Reset Filter
						</Button>
							</div>
						</div>
					</Col>
					<Divider />
					{filteredHits.map((item, index) => {
						return <>
							<Col span={3}>
							<div>{index}{item.firstName}</div>
							</Col>
							<Col span={4}>
								<div> {item.african_country.value}</div>
							</Col>
							<Col span={3}>
								<div>{item.country}</div>
							</Col>
							<Col span={3}>
								<div>
									{item.interests && item.interests[0] && item.interests[0].id && item.interests[0].text.split(' ')[0]} </div>
							</Col>
							<Col span={3}>
								<div>
									{item.interests && item.interests[1] && item.interests[1].text && item.interests[1].text.split(' ')[0]}
								</div>
							</Col>
							<Col span={3}>
								<div>
									{item.interests && item.interests[2] && item.interests[2].text && item.interests[2].text.split(' ')[0]}

								</div>
							</Col>
							<Col span={4} style={{
								display: 'flex',
								justifyContent: 'space-evenly'
							}}>
								<EditDrawer
									key={item.objectID}
									user={item} fetchData={(re) => {

								}
								} />
								<a>Freeze</a>
								<Popconfirm
									title="Are you sure delete this user?"
									onConfirm={() => {

										setDeletedUsers([...deletedUsers, item.objectID])

										deleteUser(item.objectID, () => {
											resetFilter(Date.now().toString())

										})

										message.info('user deleted')

									}}
									okText="Yes"
									cancelText="No"
								>
									<a href="#"><Icon type={'delete'} style={{ color: 'red' }} /></a>
								</Popconfirm>
							</Col>
							<Divider />
						</>
					})}
				</Row>
			</InfiniteScroll>
		</div>
	)
}

const RefinementList = ({ items, refine, operator, filters }) => {

	const [value, setValue] = useState(undefined)

	useEffect(() => {

		refine('')

		setValue(undefined)
	}, [filters.reset])

	return (
		<Select
			value={value}
			allowClear={true}
			clearIcon={
				<Icon type={'close-circle'}
					onClick={() => {
						refine('')
						setValue(undefined)
					}}
				/>
			}
			placeholder={'Nationality'}
			style={{ width: '100%' }}
		>
			{
				allCountries.length > 1 && allCountries.map(item => (
					<Option
						key={item.name}
						value={item.name}
						onClick={event => {
							setValue(item.name)
							refine(item.name)
						}}
					>
						{item.name}
					</Option>
				))
			}
		</Select>

	)
};


const RefinementListAfCountry = ({ items, refine, africanCountry, filters, resetACountryFilter }) => {
	const [value, setValue] = useState(undefined)

	useEffect(() => {
		refine('')
		setValue(undefined)
	}, [filters.reset])



	return (
		<Select
			value={value}
			allowClear={true}
			clearIcon={<Icon type={'close-circle'} onClick={() => {
				refine('')
				setValue(undefined)
			}} />}
			placeholder={'African Country'}
			style={{ width: '100%' }}>
			{africanCountry.length > 1 && africanCountry.map(item => (
				<Option
					key={item.id}
					value={item.value}
					onClick={() => {
						setValue(item.value)
						refine(item.value);

					}}

				>{item.value}</Option>
			))}
		</Select>
	)
};

const RefinementListInterests = ({
	items,
	refine,
	interests,
	filters
}) => {

	const [value, setValue] = useState(undefined)

	useEffect(() => {
		setValue(undefined)


		refine('')

	}, [
		filters.reset
	])


	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

			<Select
				value={value}

				allowClear={true} clearIcon={<Icon type={'close-circle'} onClick={() => {

					refine('')
					setValue(undefined)
				}} />} placeholder={'interest'} style={{ width: '100%' }}>

				{interests.map(item => (
					<Option value={item.text} key={item.id}
						onClick={event => {
							// event.preventDefault();
							setValue(item.text)
							refine(item.text);
						}}
					>{item.text}</Option>
				))}
			</Select>
		</div>
	)
};

function mapStateToProps(state) {
	return {
		user: state.auth.user.user,
		users: state.users,
		interests: state.interest.interests,
		africanCountry: state.africanCountries.countries,
		filters: state.filter
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		resetFilter: (text) => {
			return dispatch(resetFilter(text))
		}, resetACountryFilter: (text) => {
			return dispatch(afFilter(text))
		}, resetNaFilter: (text) => {
			return dispatch(countryFilter(text))
		}, resetInFilter: (text) => {
			return dispatch(interestFilter(text))
		}
	}
}


export const CustomRefinementListAF = connect(mapStateToProps, mapDispatchToProps)(connectRefinementList(RefinementListAfCountry));

export const CustomRefinementListCountry = connect(mapStateToProps, mapDispatchToProps)(connectRefinementList(RefinementList));

export const CustomRefinementListInterest = connect(mapStateToProps, mapDispatchToProps)(connectRefinementList(RefinementListInterests));

export default connect(mapStateToProps, mapDispatchToProps)(connectInfiniteHits(Hit))
