import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";
import {createDynamicLink} from "../api";


export const getInvitations = (cb) => async (dispatch ) => {
    Backend.database().ref('invitations').on('value', (invitations) => {

        let all = []
        invitations.val() &&
        Object.keys(invitations.val()).map(item => {
            all.push({
                id: item,
                date: invitations.val()[item].date,
                max: invitations.val()[item].max,
                url: invitations.val()[item].url,
                description: invitations.val()[item].description,
            })
        })
        cb()
        return dispatch({type: types.GET_INVITATIONS, payload: all});
    })
}


export const updateInvitation = (key, data, cb) => async dispatch => {



    let updates = {}
        updates[`invitations/${key}/date`] = data.date
        updates[`invitations/${key}/max`] = data.max
        updates[`invitations/${key}/description`] = data.description
        Backend.database().ref().update(updates).then(() => {
            cb()
        })
}

export const deleteInvitation = (key, cb) => async dispatch => {
    Backend.database().ref(`invitations/${key}`).remove().then(() => {
        cb()
    })
}
export const addInvitation = (data, cb) => async dispatch => {
    Backend.database().ref(`invitations`).push(data).then((res) => {
        createDynamicLink(res.key).then((link) => {

            let updates = {}
            updates[`invitations/${res.key}/url`] = link.shortLink
            Backend.database().ref().update(updates).then(() => {
                cb()
            })
        })
    })
}