//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    refs: [],

}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_REFS:

            return {
                ...state,
                refs: action.payload
            }

        default:
            return state
    }
}

