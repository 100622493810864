import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getSoftSkills = () => async dispatch => {
    Backend.database().ref('soft_skills').on('value', (soft_skills) => {

        let all = []
        
        Object.keys(soft_skills.val()).map(item => {
            all.push({id: item, value: soft_skills.val()[item]})
        })

        return dispatch({type: types.GET_SOFT_SKILLS, payload: all});
    })
}

export const updateSoftSkills = (key, data, cb) => async dispatch => {
    let updates = {}
    updates[`soft_skills/${key}`] = data.toString()
    Backend.database().ref().update(updates).then(() => {
        cb()
    })
}

export const deleteSoftSkills = (key, cb) => async dispatch => {
    Backend.database().ref(`soft_skills/${key}`).remove().then(() => {
        cb()
    })
}
export const addSoftSkills = (data, cb) => async dispatch => {

    Backend.database().ref(`soft_skills`).push(data.toString()).then(() => {
        cb()
    })
}
