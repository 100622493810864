import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";

export const getFeedback = (cb) => async dispatch => {
    Backend.database().ref('feedback').on('value', (feedback) => {

        let all = []
        Object.keys(feedback.val()).map(item => {
            all.push({
                key: item,
                subject: feedback.val()[item].subject,
                message: feedback.val()[item].message,
                author: feedback.val()[item].author,
                createdAt: feedback.val()[item].createdAt
            })
        })

        cb()
        return dispatch({type: types.GET_FEEDBACK, payload: all})
    })
}


export const deleteFeedback = async (id, cb)  => {


    await Backend.database().ref(`feedback/${id}`).remove()
    cb()

}
