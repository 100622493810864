import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getExperiences = () =>async dispatch => {
    Backend.database().ref('experience').on('value' , (experience)=>{

        let all = []




        Object.keys(experience.val()).map(item=> {
            all.push({key : item , value :experience.val()[item].value ,  order:experience.val()[item].order })
        })

        return dispatch({type: types.GET_EXPERIENCE, payload: all});
    } )
}


export const saveExperience = (data  , cb   ) =>async dispatch => {



    Backend.database().ref(`experience`).set(data).then(()=>{
        cb()
    })
}
