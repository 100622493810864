import React from 'react'
import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router'
import {signin, updateUser} from "../actions/auth";

import {Button, Drawer, Form, Icon, Input, Tooltip, Upload , Divider} from "antd";

function Profile(props) {

    const handleSubmit = e => {
        e.preventDefault()

        const {form} = props

        form.validateFields((err, values) => {


            if (err) {
                console.log(err)
            } else {

                props.updateProfile(values , ()=>{

                })
            }

        })
    }

    const {getFieldDecorator} = props.form;

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 5},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 12},
        },
    };

    return (
        <div style={{width: '50%'}}>

            <h2>Edit Profile</h2>
            <Form  onSubmit={handleSubmit.bind(this)} className="login-form">
                <Form.Item>
                    {getFieldDecorator('displayName', {
                        rules: [{required: false, message: 'displayName required'}],
                        initialValue: props.user && props.user.displayName
                    })

                    (
                        <Input

                            size='large'
                            placeholder="displayName"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('newEmail', {
                        rules: [{required: false, message: 'displayName required'}],
                    })

                    (
                        <Input

                            size='large'
                            placeholder="New email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('phoneNumber', {
                        rules: [{required: false, message: 'phoneNumber required'}],
                        initialValue: props.user &&props.user.phoneNumber
                    })

                    (
                        <Input

                            size='large'
                            placeholder="Phone Number"
                        />,
                    )}
                </Form.Item>


                <Form.Item>
                {getFieldDecorator('newPassword', {
                    rules: [{required: false, message: 'displayName required'}],
                })

                (
                    <Input.Password

                        size='large'
                        placeholder="New password"
                    />,
                )}
            </Form.Item>

                 <Divider/>

                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{required: true, message: 'old email required'}],
                        initialValue:  props.user && props.user.email
                    })

                    (
                        <Input

                            size='large'
                            placeholder="old email"
                        />,
                    )}
                </Form.Item>

                <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{required: true, message: 'old password  required'}],
                })

                (
                    <Input.Password

                        size='large'
                        placeholder="Old password"
                    />,
                )}
            </Form.Item>


                <Button
                    block

                    size='large'
                    type="primary"
                    htmlType="submit"
                >
                    Valider
                </Button>
            </Form>
        </div>
    )


}

function mapStateToProps(state) {
    return {

        user: state.auth.user.user
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        Login: (formProps, callback) => {
            return dispatch(signin(formProps, callback))
        },

            updateProfile: (formProps, callback) => {
            return dispatch(updateUser(formProps, callback))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(Profile)))
