
//--------auth----------

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const AUTH_ERROR = 'AUTH_ERROR'

//--------END auth----------



//-----------configuration -------------------

export const GET_CONFIGURATION = 'GET_CONFIGURATION'

export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION'


//-----------END configuration -------------------

//-----------users -------------------

export const GET_USERS = 'GET_USERS'

export const UPDATE_USER = 'UPDATE_USER'


//-----------END users -------------------


//-----------languages -------------------

export const GET_LANGUAGES = 'GET_LANGUAGES'

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'


//-----------END LANGUAGE -------------------
// -----------africanCountries -------------------

export const GET_AFRICAN_COUNTRIES = 'GET_AFRICAN_COUNTRIES'


//-----------END africanCountries -------------------
// -----------Hard Skills-------------------

export const GET_HARD_SKILLS = 'GET_HARD_SKILLS'


//-----------END Hard Skills-------------------
// -----------Soft Skills-------------------

export const GET_SOFT_SKILLS = 'GET_SOFT_SKILLS'


//-----------END Soft Skills-------------------
// -----------Interests-------------------

export const GET_INTERESTS = 'GET_INTERESTS'


//-----------END interests-------------------
// -----------Experience-------------------

export const GET_EXPERIENCE = 'GET_EXPERIENCE'

//-----------END Experience-------------------
// -----------Emails-------------------

export const GET_EMAILS = 'GET_EMAILS'

//-----------END Emails-------------------
// -----------Filter-------------------

export const RESET_FILTER = 'RESET_FILTER'
export const AFRICAN_COUNTRIES_FILTER = 'AFRICAN_COUNTRIES_FILTER'
export const INTERESTS_FILTER = 'INTERESTS_FILTER'
export const NATIONALITY_FILTER = 'NATIONALITY_FILTER'

//-----------END Filter-------------------
// -----------Refs-------------------

export const GET_REFS = 'GET_REFS'


//-----------END Refs-------------------

// -----------Invitations-------------------

export const GET_INVITATIONS = 'GET_INVITATIONS'

export const EDIT_INVITATIONS = 'EDIT_INVITATIONS'

export const ADD_INVITATIONS = 'ADD_INVITATIONS'

export const DELETE_INVITATIONS = 'DELETE_INVITATIONS'


//-----------END Invitation-------------------


//---------------Feedback----------
export const GET_FEEDBACK = 'GET_FEEDBACK'

//---------------END Feedback----------

//---------------Reports----------
export const GET_REPORTS = 'GET_REPORTS'

//---------------END reports----------


