import React from 'react'
import {connectPagination} from 'react-instantsearch-dom'
import {Pagination} from 'antd'
import $ from "jquery";


function HardSkillsPagination({currentRefinement, nbPages, refine, createURL}) {
    return (
        <Pagination
            defaultCurrent={1}
            total={nbPages * 20}
            defaultPageSize={20}
            pageSize={20}
            current={currentRefinement}
            onChange={page => {

                $(".ant-layout").animate({ scrollTop: 0 }, "slow")
                refine(page)
            }}
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10
            }}
        />
    )
}


export default connectPagination(HardSkillsPagination)