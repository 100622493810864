//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    isSuccess: false,
    isLoading: false,
    user: null,
    prevPath: null,
    error: null ,
}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.LOGIN:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                user: action.payload,
                error:null
            }

        case types.AUTH_ERROR:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.payload
            }

        case types.LOGOUT:
            return initialState

        default:
            return state
    }
}

/*

export const home = (user) => {
	//return Path.surveyList()
	if (user.role === 'super_admin')
		return Path.regionsList()
	else
		return Path.regionById(user.region)
}*/
