
import React, { useEffect, useState } from 'react'

import {
	addLanguages,
	deleteLanguages,
	getLanguages,
	updateLanguages
} from '../actions/languages'

import { connect } from "react-redux"

import { Button, Icon, List, message, Modal, Popconfirm, Spin, Table } from 'antd';

import EdiText from 'react-editext'

import '../App.css'

function Languages(props) {

	const [openModalAdd, setOpenModalAdd] = useState(false)
	const [loader, setLoader] = useState(true)

	useEffect(() => {
		props.getAllLanguages(() => {
			setLoader(false)
		})
	}, [])

	const columns = [
		{
			title: 'Language',
			dataIndex: 'value',
			key: 'value',
			render: (item, id) => (
				<EdiText
					hideIcons
					viewContainerClassName='textEdit'
					type="textarea"
					saveButtonContent="Apply"
					cancelButtonContent={<strong>Cancel</strong>}
					editButtonClassName={'editBtn'}
					editButtonContent={
						<div style={{
							padding: '5px 8px',
							borderRadius: '5px',
							backgroundColor: '#40A9FF'
						}}>
							<Icon className={'btn'}
								type={'edit'}
								style={{ color: 'white' }}
							/>
						</div>
					}
					value={item}
					onSave={(data) => {
						props.update(id.id, data, () => {
							setLoader(true)
							props.getAllLanguages(() => {
								setLoader(false)
							})
						})

					}}
				/>
			)
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			width: '10%',
			key: 'id',
			render: (item) => (
				<Popconfirm
					title="Are you sure delete this ?"
					onConfirm={() => {
						props.delete(item, () => {
							setLoader(true)
							props.getAllLanguages(() => {
								setLoader(false)
								message.info('deleted')
							})
						})
					}}
					okText="Yes"
					cancelText="No"
				>
					<Button
						type={'dashed'}
						icon={'delete'}
						style={{
							color: 'red',
							fontSize: 20
						}}
					/>
				</Popconfirm>
			)
		}
	];
	
	console.log(loader)

	return (

		loader ? <Spin /> :

			<div
				style={{
					backgroundColor: 'white'
				}}
			>
				<Button
					style={{
						float: 'right',
						margin: '5px',
						zIndex: 99
					}}
					onClick={() => {
						setOpenModalAdd(true)
					}}
					type="primary"
				>
					Add Language
				</Button>

				<Modal
					title="Add Language"
					visible={openModalAdd}
					footer={null}
					onCancel={() => {
						setOpenModalAdd(false)
					}}
				>
					<EdiText
						viewContainerClassName='textEdit'
						type="text"
						saveButtonContent="Apply"
						cancelButtonContent={<strong>Cancel</strong>}
						editButtonContent="Edit"
						value={''}
						editing={openModalAdd}
						onCancel={() => {
							setOpenModalAdd(false)
						}}
						onSave={(data) => {

							props.addLanguage(data, () => {
								setLoader(true)
								props.getAllLanguages(() => {
									setOpenModalAdd(false)
									message.success('added with success')
									setLoader(false)
								})
							})
						}}
					/>
				</Modal>
				<Table dataSource={props.languages.languages} columns={columns} rowKey={'id'} />
			</div>
	)
}

const mapStateToProps = (state) => {
	return {
		languages: state.languages
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getAllLanguages: (cb) => {
			return dispatch(getLanguages(cb))
		},
		update: (key, data, cb) => {
			return dispatch(updateLanguages(key, data, cb))
		},
		delete: (key, cb) => {
			return dispatch(deleteLanguages(key, cb))
		},
		addLanguage: (data, cb) => {
			return dispatch(addLanguages(data, cb))
		},
	}

}
export default connect(mapStateToProps, mapDispatchToProps)(Languages)