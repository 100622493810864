import * as types from "../constants/Action_types";

export const afFilter = (text)=>async (dispatch)=>{

    dispatch({type: types.AFRICAN_COUNTRIES_FILTER, payload: text});


}
export const countryFilter = (text)=>async (dispatch)=>{

    dispatch({type: types.NATIONALITY_FILTER, payload: text});


}
export const interestFilter = (text)=>async (dispatch)=>{

    dispatch({type: types.INTERESTS_FILTER, payload: text});


}
export const resetFilter= (text)=>async (dispatch)=>{

    dispatch({type: types.RESET_FILTER , payload: text});


}
