import React, {useEffect} from "react";
import {deleteArchivedUser, getArchivedUsers, restoreUser} from "../api";
import {addLanguages, deleteLanguages, updateLanguages} from "../actions/languages";
import {connect} from "react-redux";
import moment from "moment";
import {Button, message, Popconfirm, Table, Tag, Tooltip} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";


const users = getArchivedUsers()


function ArchivedUsers(props) {
    const renderFlagColor = (flag) => {
        if (flag === 1) {
            return 'red'
        }

        if (flag === 2) {
            return '#FFBC0F'
        }

        return 'lightgrey'
    }


    const columns = [
        {
            title: 'Flag',
            key: 'flag',
            width: '7%',
            render: (text, record) => (
                <div
                    style={{
                        backgroundColor: renderFlagColor(record.flag),
                        width: 10,
                        height: 10,
                        borderRadius: 5
                    }}
                />
            ),
        },
        {
            title: 'Identifier',
            dataIndex: 'uid',
            key: 'uid',
            ellipsis: true,
            width: '15%',
            render: (item) => (
                <>
                    <CopyToClipboard

                        text={item}
                        onCopy={() => message.success('copied')}>
                        <Button
                            style={{marginLeft: 15}}
                            icon={'copy'}
                            type={'dashed'}
                        />
                    </CopyToClipboard>
                    <span style={{marginLeft: '3px'}}>
                    {item.toString().substring(0, 15) + '...'}
                    </span>
                </>
            )
        },
        {
            title: 'African Country',
            dataIndex: 'african_country.value',
            key: 'african_country',
            ellipsis: true,

        },
        {
            title: 'Nationality',
            dataIndex: 'country',
            key: 'country',
            ellipsis: true,

        },
        {
            title: 'Interest#1',
            dataIndex: 'interests[0].text',
            key: 'interest1',
            ellipsis: true,
            render: interest => (
                <span>{interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'Interest#2',
            dataIndex: 'interests[1].text',
            key: 'interest2',
            ellipsis: true,
            render: interest => (
                <span>{interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'Interest#3',
            dataIndex: 'interests[2].text',
            key: 'interest3',
            ellipsis: true,
            render: interest => (
                <span>{interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'SignUp Date',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            width: '12%',
            sorter: (a, b) => 0,
            render: (text, record) => (
                <span>
                    {
                        record.created_at

                            ? moment(record.created_at).format('DD-MM-YY')

                            : ''
                    }
                </span>
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, record) => (
                <Tag
                    color={record.disabled ? 'volcano' : 'green'}
                >
                    {record.disabled ? 'Frozen' : 'Active'}
                </Tag>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (text, record) => (
                <Button.Group>
                <Popconfirm
                    title={
                        "Are you sure you want to restore this account?"
                    }
                    onConfirm={() => {

                        restoreUser(record.uid, () => {

                        })
                    }}
                    okText="Proceed"
                    cancelText="Cancel"
                >
                    <Tooltip title={'Restore Account'}>
                        <Button icon={'unlock'} type='dashed'> Restore </Button>
                    </Tooltip>
                </Popconfirm>

                <Popconfirm
                    title={
                        "Are you sure you want to delete this account?"
                    }
                    onConfirm={() => {

                        deleteArchivedUser(record.uid, () => {
                            props.getAllArchivedUsers(() => {

                                console.log('end')
                            })
                        })
                    }}
                    okText="Proceed"
                    cancelText="Cancel"
                >
                    <Tooltip title={'Delete Account'}>
                        <Button icon={'delete'} type='danger'/>
                    </Tooltip>
                </Popconfirm>
                    </Button.Group>
            ),
        },
    ]
    useEffect(() => {
        props.getAllArchivedUsers(() => {
        })
    }, [])
    return (
        <div>
            <Table
                columns={columns}
                dataSource={props.archivedUsers}
                pagination={true}
                rowKey={'uid'}
            />
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        archivedUsers: state.users.archived
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllArchivedUsers: (cb) => {
            return dispatch(getArchivedUsers(cb))
        },
        update: (key, data, cb) => {
            return dispatch(updateLanguages(key, data, cb))
        },
        delete: (key, cb) => {
            return dispatch(deleteLanguages(key, cb))
        },
        addLanguage: (data, cb) => {
            return dispatch(addLanguages(data, cb))
        },


    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedUsers)