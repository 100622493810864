import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getInterests = () => async dispatch => {
    Backend.database().ref('interests').on('value', (interests) => {

        let all = []
        Object.keys(interests.val()).map(item => {
            all.push({id: item, order:interests.val()[item].order , text: interests.val()[item].text, icon: interests.val()[item].icon})
        })

        return dispatch({type: types.GET_INTERESTS, payload: all.sort((a, b) => (a.order - b.order))})
    })
}

export const updateInterests = (key, values, cb) => async dispatch => {
    if (values.image) {
        let uploadTask = Backend.storage().ref(`/icons/${Date.now()}`).put(values.image.file.originFileObj).then(
            res => {
                res.ref.getDownloadURL().then((downloadURL) => {

                    try {

                        let updates = {}

                        updates[`interests/${key}/icon`] = downloadURL

                        if (values.text)
                        updates[`interests/${key}/text`] = values.text

                        Backend.database().ref().update(updates).then(
                            () => {
                                cb()
                            }
                        );
                    } catch (error) {

                        console.log(error)
                    }
                })
            }
        )
    } else {
        try {

            let updates = {}
            if (values.text)
           {
               updates[`interests/${key}/text`] = values.text
               Backend.database().ref().update(updates).then(
                   () => {
                       cb()
                   }
               );
           }
        } catch (error) {
            console.log(error)
        }
    }
}

export const deleteInterests = (key, cb) => async dispatch => {
    Backend.database().ref(`interests/${key}`).remove().then(() => { cb() })
}

export const addInterests = (values, cb) => async dispatch => {
    let uploadTask = Backend.storage().ref(`/icons/${Date.now()}`).put(values.image.file.originFileObj).then(
        res => {
            res.ref.getDownloadURL().then((downloadURL) => {

                try {

                    Backend.database().ref('interests').push({icon: downloadURL, text: values.text , order : values.order}).then(
                        () => {
                            cb()
                        }
                    )
                } catch (error) {
                    console.log(error)
                }
            })
        }
    )
}

export const saveInterests = (items , cb) => async dispatch => {
    let arr = new Array()

    items.map(item => {
        arr[item.id] = {text :  item.text , icon : item.icon , order : item.order}
    })

    Backend.database().ref(`interests/`).set(arr).then(() => { cb() })
}

