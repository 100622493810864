import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";

export const getEmails = (cb) => async dispatch => {


    Backend.database().ref('/email').on('value', (snapshot) => {

        dispatch({type: types.GET_EMAILS, payload: snapshot.val()});
        cb()
    })
}

export const addEmail = (data , cb ) => async dispatch => {
    Backend.database().ref(`email`).set(data).then(()=>{
        cb()
    })
}
