
import React, { useEffect, useState } from 'react'
import { addSoftSkills, deleteSoftSkills, getSoftSkills, updateSoftSkills } from "../actions/softSkills";

import { connect } from "react-redux";
import { List, Avatar, Icon, Popconfirm, message, Button, Modal } from 'antd';
import EdiText from 'react-editext'
import _ from 'lodash'
function SoftSkills(props) {

    const [openModalAdd, setOpenModalAdd] = useState(false)

    useEffect(() => {
        props.getAllSoftSkills()
    }, [])


    return (
        <div style={{
            backgroundColor : 'white' ,
            padding: '15px'
        }}>
        <List
            header={<div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'

            }}>
                <Button onClick={() => {
                    setOpenModalAdd(true)
                }} type="primary">Add softSkills</Button>

                <Modal
                    title="Add softSkills"
                    visible={openModalAdd}
                    footer={null}
                    onCancel={() => { setOpenModalAdd(false) }}

                >
                    <EdiText
                        hideIcons
                        viewContainerClassName='textEdit'
                        type="text"
                        saveButtonContent="Apply"
                        cancelButtonContent={<strong>Cancel</strong>}
                        editButtonClassName={'editBtn'}
                        editButtonContent={<div style={{
                            padding: '5px 8px',
                            borderRadius: '5px',
                            backgroundColor : '#40A9FF'}}>
                            <Icon  className={'btn'}
                                   type={'edit'}
                                   style={{color : 'white'}}
                            />
                        </div>}
                        value={''}
                        editing={openModalAdd}
                        onCancel={() => {
                            setOpenModalAdd(false)
                        }}
                        onSave={(data) => {

                            props.addSoftSkills(data, () => {
                                props.getAllSoftSkills()
                                setOpenModalAdd(false)
                                message.success('added with success')
                            })
                        }}
                    />

                </Modal>
            </div>}
            itemLayout="horizontal"
            dataSource={_.sortBy(props.soft_skills.soft_skills, ['value'])}
            renderItem={item => (
                <List.Item

                    actions={[
                        <Popconfirm
                            title="Are you sure delete this ?"
                            onConfirm={() => {
                                props.delete(item.id, () => {
                                    props.getAllSoftSkills()
                                    message.info('deleted')
                                })
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type={'danger'}
                                icon={'delete'}
                                style={{
                                    color: 'white',
                                    fontSize: 20
                                }}
                            />
                        </Popconfirm>
                    ]}
                >
                    <List.Item.Meta
                        title={
                            <EdiText
                                hideIcons
                                viewContainerClassName='textEdit'
                                type="textarea"
                                saveButtonContent="Apply"
                                cancelButtonContent={<strong>Cancel</strong>}
                                editButtonClassName={'editBtn'}
                                editButtonContent={<div style={{
                                    padding: '5px 8px',
                                    borderRadius: '5px',
                                    backgroundColor : '#40A9FF'}}>
                                    <Icon  className={'btn'}
                                           type={'edit'}
                                           style={{color : 'white'}}
                                    />
                                </div>}
                                value={item.value}
                                onSave={(data) => {

                                    props.update(item.id, data, () => {

                                        props.getAllSoftSkills()
                                    })

                                }}
                            />
                        }
                    />
                </List.Item>
            )}
        />

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        soft_skills: state.soft_skills
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSoftSkills: () => {
            return dispatch(getSoftSkills())
        },
        update: (key, data, cb) => {
            return dispatch(updateSoftSkills(key, data, cb))
        },
        delete: (key, cb) => {
            return dispatch(deleteSoftSkills(key, cb))
        },
        addSoftSkills: (data, cb) => {
            return dispatch(addSoftSkills(data, cb))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftSkills)