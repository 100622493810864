import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";

export const getReports = (cb) => async dispatch => {
    Backend.database().ref('reports').on('value', (res) => {

        let all = []

        if (res.val()) {
            Object.keys(res.val()).map(item => {
                let reps = []
                Object.keys(res.val()[item]).map(key => {
                    reps.push({
                        id: key,
                        who: res.val()[item][key].who,
                        why: res.val()[item][key].why,
                        timestamp: res.val()[item][key].timestamp
                    })
                })
    
    
                all.push({user: item, reporters: reps})
    
            })
        }
        
        cb()
        return dispatch({type: types.GET_REPORTS, payload: all})
    })
}


export const deleteReport = async (id, cb) => {

    await Backend.database().ref(`reports/${id}`).remove()
    cb()

}
