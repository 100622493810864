import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import {Button, Form, Input, message, Spin} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {addEmail, getEmails} from "../actions/emails";


let  Editor









function EmailConfiguration(props) {
    const { TextArea } = Input;
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        props.getEmail(() => {

            setLoading(false)
        })

    }, [])


    const handleSubmit = e => {
        e.preventDefault()
        const {form} = props

        form.validateFields((err, values) => {
            if (err)
                console.log(err)
            else {
                setLoading(true)
                props.saveEmail(values, () => {
                    props.getEmail(() => {
                        setLoading(false)
                        message.success('saved')
                    })
                })
            }
        })
    }
    const {getFieldDecorator} = props.form;
    return (
        <div style={{
            backgroundColor: 'white',
            padding: 24
        }}>

            <Form onSubmit={handleSubmit.bind(this)}>
                <Form.Item label={'Object'}>
                    {getFieldDecorator('object', {
                        rules: [{required: true, message: 'object required '}],
                        initialValue:props.emails.email && props.emails.email.object
                    })
                    (
                        <Input
                            size='large'
                            placeholder=" object"
                        />,
                    )}
                </Form.Item>
                <Form.Item label={'content'}>
                    {getFieldDecorator('content', {
                        rules: [{required: true, message: 'content  required'}],
                        initialValue: props.emails.email && props.emails.email.content
                    })
                    (
                        <TextArea rows={20} />,
                    )}
                </Form.Item>

                {loading ? <Spin/> :
                    <Button htmlType="submit">submit</Button>
                }
            </Form>

            <div>
                {`use {{var}} to define dynamic objects \n example {{firstName}}  {{lastName}}`}
            </div>


        </div>)
}

const mapDispatchToProps = (dispatch) => {
    return {

        getEmail: (cb) => {
            return dispatch(getEmails(cb))
        }, saveEmail: (data, cb) => {
            return dispatch(addEmail(data, cb))
        },
    }
}


function mapStateToProps(state) {
    return {
        emails: state.email
    }
}










export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(EmailConfiguration)))

