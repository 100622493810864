import * as types from '../constants/Action_types'

const initialState = {
    invitations: [],

}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_INVITATIONS:


            return {
                ...state,
                invitations: action.payload
            }

        default:
            return state
    }
}