import React, { useEffect, useState } from 'react'
import { addInterests, deleteInterests, getInterests, saveInterests } from "../actions/interests"
import { sortableContainer, sortableElement, sortableHandle, } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { connect } from "react-redux"
import { Avatar, Button, Empty, Form, Icon, Input, message, Modal, Popconfirm, Upload } from 'antd'
import EditInterestDrawer from "../components/EditInterestDrawer"

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import AddInterest from '../components/AddInterest'

function Interests(props) {

	const [openModalAdd, setOpenModalAdd] = useState(false)
	const [items, setItems] = useState()
	
	useEffect(() => {
		props.getAllInterests().then(() => {
			setItems(props.interests.interests)
		})
	}, [])

	useEffect(() => {
		setItems(props.interests.interests)
	}, [props.interests])

	const SingleInterest = ({ interest, isDragging }) => {
		return (
			<div
				style={{
					height: 60,
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
					backgroundColor: 'white',
					alignItems: 'center',
					marginBottom: 5,
					paddingLeft: 10,
					paddingRight: 10,
					borderRadius: 4,
					boxShadow: isDragging ? '0px 0px 15px 0px rgba(196,196,196,1)' : undefined
				}}
			>
				<div>
					<img
						style={{
							marginRight: 5,
							width: 50,
							height: 50,
							objectFit: 'contain'
						}}
						src={interest.icon}
					/>
					{interest.text}
				</div>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<EditInterestDrawer interest={interest} />
					<Popconfirm
						title="Are you sure delete this ?"
						onConfirm={() => {
							props.delete(interest.id, () => {
								props.getAllInterests()
								message.info('deleted')
							})
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button
							icon='delete'
							type="danger"
							style={{
								marginLeft: 5
							}}
						>

						</Button>
					</Popconfirm>
				</div>
			</div>
		)
	}

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result

		if (! destination) {
			return
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return
		}

		// Reorder
		let newItems = [...items]
		let movedItem = newItems.splice(source.index, 1)
		newItems.splice(destination.index, 0, movedItem[0])
		newItems = newItems.map((item, index) => ({...item, order: index}))
		setItems(newItems)
	}

	if (items && items.length > 0) {
		return (
			<React.Fragment>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 10
					}}
				>
					<AddInterest />
					<Button
						onClick={() => {
							props.saveInterests(items, () => {
								props.getAllInterests()
								message.success('Saved')
							})
						}}
						type="primary"
					>
						Save Orders
					</Button>
				</div>

				<DragDropContext
					onDragEnd={onDragEnd}
				>
					<Droppable droppableId='1'>
						{
							(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{
										items.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														key={item.id}
														{...provided.dragHandleProps}
														{...provided.draggableProps}
													>
														<SingleInterest
															interest={item}
															isDragging={snapshot.isDragging}
														/>
													</div>
												)}
											</Draggable>
										))
									}

									{provided.placeholder}
								</div>
							)
						}
					</Droppable>
				</DragDropContext>
			</React.Fragment>
		)
	} else {
		return (
			<Empty />
		)
	}
}

const mapStateToProps = (state) => {
	return {
		interests: state.interest
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getAllInterests: () => {
			return dispatch(getInterests())
		},
		delete: (key, cb) => {
			return dispatch(deleteInterests(key, cb))
		},
		addInterests: (data, cb) => {
			return dispatch(addInterests(data, cb))
		},
		saveInterests: (items, cb) => {
			return dispatch(saveInterests(items, cb))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Interests)