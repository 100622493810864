//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    hard_skills: [],

}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_HARD_SKILLS:


            return {
                ...state,
                hard_skills: action.payload
            }

        default:
            return state
    }
}

/*

export const home = (user) => {
	//return Path.surveyList()
	if (user.role === 'super_admin')
		return Path.regionsList()
	else
		return Path.regionById(user.region)
}*/
