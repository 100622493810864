import React, { useEffect, useState } from 'react'
import { sortableContainer, sortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { connect } from "react-redux";
import EdiText from 'react-editext'
import { Button, Icon, message } from "antd";
import { getExperiences, saveExperience } from "../actions/experience";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

function Experience(props) {

	const [openModalAdd, setOpenModalAdd] = useState(false)
	const [items, setItems] = useState()
	
	useEffect(() => {
		props.getExperience().then(() => {
			setItems(props.experience.experience)
		})
	}, [])


	const onSortEnd = ({ oldIndex, newIndex }) => {
		setItems(arrayMove(items, oldIndex, newIndex))
	}


	const SingleExperience = ({item, isDragging}) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					height: 50,
					backgroundColor: 'white',
					marginBottom: 5,
					alignItems: 'center',
					paddingLeft: 10,
					paddingRight: 10,
					borderWidth: 1,
					borderRadius: 4,
					boxShadow: isDragging ? '0px 0px 15px 0px rgba(196,196,196,1)' : undefined
				}}
			>
				<EdiText
					viewContainerClassName='textEdit'
					type="text"
					saveButtonContent="Apply"
					cancelButtonContent={<strong>Cancel</strong>}
					hideIcons
					editButtonClassName={'editBtn'}
					editButtonContent={
						<div style={{
							padding: '5px 8px',
							borderRadius: '5px',
							backgroundColor : '#40A9FF'}}>
						<Icon  className={'btn'}
						type={'edit'}
							   style={{color : 'white'}}
					/>
					</div>
					}
					value={item.value}
					onSave={(data) => {
						// console.log(data)
						items[item.order].value = data

						// setItems(items)

						
					}}
				/>

				<Button
					onClick={() => {
						let temp = items.filter(a => a.order !== item.order)
						temp = temp.map((item, index) => ({...item, order: index, key: `${index}`}))
						setItems(temp)
					}}
					style={{
						marginLeft: 5,
						fontSize: 15,
						color: 'white',
						cursor: 'pointer'
					}}
					type={'danger'}
					icon={'delete'}
				/>
			</div>
		)
	}

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result

		if (! destination) {
			return
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return
		}

		// Reorder
		let newItems = [...items]
		let movedItem = newItems.splice(source.index, 1)
		newItems.splice(destination.index, 0, movedItem[0])
		newItems = newItems.map((item, index) => ({...item, order: index, key: `${index}`}))
		setItems(newItems)
	}

	const ExperienceList = () => {
		
		if (items && items.length > 0) {
			return items.map((item, index) => {
				return (
					<Draggable
						key={item.key}
						draggableId={item.key}
						index={index}
					>
						{(provided, snapshot) => (
							<div
								key={item.key}
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
							>
								<SingleExperience
									item={item}
									isDragging={snapshot.isDragging}
								/>
							</div>
						)}
					</Draggable>
				)
			})
		} else {
			return null
		}
	}


	return (
		<div>
			<div
				style={{
					marginBottom: 5,
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Button
					type={'primary'}
					onClick={() => {

						props.saveExperience(items, () => {
							message.success('saved')
						})
					}}
				>
					Save
				</Button>

				<Button
					type={'primary'}
					onClick={() => {
						setItems([...items, { key: `${items.length}`, value: '<1', order: items.length } ])
					}}
				>
					Add
				</Button>
			</div>

			

			<DragDropContext
				onDragEnd={onDragEnd}
			>
				<Droppable droppableId={'1'}>
					{
						(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<ExperienceList/>

								{provided.placeholder}
							</div>
						)
					}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		experience: state.experience
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		saveExperience: (data, cb) => {
			return dispatch(saveExperience(data, cb))
		}, getExperience: () => {
			return dispatch(getExperiences())
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Experience)
