import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getAfricanCountries = () => async dispatch => {

    let updates = {}
    Backend.database().ref('african_countries').on('value', (countries) => {
        let all = []
        
        countries.val() && Object.keys(countries.val()).map(item => {
            all.push({ id: item, value: countries.val()[item].value, flag: countries.val()[item].flag })
        })

        dispatch({ type: types.GET_AFRICAN_COUNTRIES, payload: all });
    })
}

export const updateAfricanCountries = (key, values, cb) => async dispatch => {
    if (values.image) {
        let uploadTask = Backend.storage().ref(`/flags/${Date.now()}`).put(values.image.file.originFileObj).then(
            res => {
                res.ref.getDownloadURL().then((downloadURL) => {
                    
                    try {
                        
                        let updates = {}

                        updates[`african_countries/${key}/flag`] = downloadURL
                        
                        if (values.value)
                            updates[`african_countries/${key}/value`] = values.value

                        Backend.database().ref().update(updates).then(
                            () => {
                                cb()
                            }
                        );
                    } catch (error) {

                        console.log(error)
                    }
                })
            }
        )
    } else {
        try {

            let updates = {}
            if (values.value) {
                updates[`african_countries/${key}/value`] = values.value
                Backend.database().ref().update(updates).then(
                    () => {
                        cb()
                    }
                );
            }
        } catch (error) {
            console.log(error)
        }
    }










}

export const deleteAfricanCountries = (key, cb) => async dispatch => {
    Backend.database().ref(`african_countries/${key}`).remove().then(() => {
        cb()
    })
}
export const addAfricanCountries = (values, cb) => async dispatch => {
    let uploadTask = Backend.storage().ref(`/flags/${Date.now()}`).put(values.image.file.originFileObj).then(
        res => {
            res.ref.getDownloadURL().then((downloadURL) => {
                
                try {
                    
                    Backend.database().ref('african_countries').push({ flag: downloadURL, value: values.text }).then(
                        () => {
                            cb()
                        }
                    );
                } catch (error) {
                    console.log(error)
                }
            })
        }
    )


}
