
import React, { useState, useEffect } from 'react'
import {
    connectHits,
    connectRefinementList,
    connectSearchBox,
    connectSortBy
} from 'react-instantsearch-dom'

import {
    Table,
    Button,
    Divider,
    Popconfirm,
    Popover,
    Tooltip,
    message,
    Tag,
    Input,
    Dropdown,
    Menu
} from 'antd'

import EditDrawer from './EditDrawer'
import {
    deleteUser,
    freezeAccount,
    unFreezeAccount,
    flagAccount,
    unFlagAccount
} from '../api'

import { connect } from 'react-redux'
import countries from '../assets/allCountries'
import moment from 'moment'

const RefineByAfricanCountry = connectRefinementList(() => null)
const RefineByCountry = connectRefinementList(() => null)
const SortByDate = connectSortBy(() => null)

function SearchBar ({refine, currentRefinement}) {
    
    return (
        <Input.Search
            placeholder='Search by first name, last name or identifier'
            onSearch={(term) => refine(term)}
            enterButton
            allowClear
        />
    )
}

const ConnectedSearchBar = connectSearchBox(SearchBar)


function Hits({ hits, refresh, onDelete, african_countries, interests, instantRefresh, onEdit, onOperation }) {

    const [editableUser, setEditableUser] = useState(undefined)
    const [editModalVisible, setEditModalVisible] = useState(false)

    const [africanCountryFilter, setAfricanCountryFilter] = useState([])
    const [countryFilter, setCountryFilter] = useState([])

    const [orderByDate, setOrderByDate] = useState('users')

    const renderFlagColor = (flag) => {
        if (flag === 1) {
            return 'red'
        }

        if (flag === 2) {
            return '#FFBC0F'
        }

        return 'lightgrey'
    }

    const columns = [
        {
            title: 'Flag',
            key: 'flag',
            width: '7%',
            render: (text, record) => (
                <div
                    style={{
                        backgroundColor: renderFlagColor(record.flag),
                        width: 10,
                        height: 10,
                        borderRadius: 5
                    }}
                />
            ),
        },
        {
            title: 'Identifier',
            dataIndex: 'objectID',
            key: 'objectID',
            ellipsis: true,
            width: '15%'
        },
        {
            title: 'African Country',
            dataIndex: 'african_country.value',
            key: 'african_country',
            ellipsis: true,
            filteredValue: africanCountryFilter,
            filters: african_countries.map((item, index) => ({ text: item.value, value: item.value})),
            onFilter: (value, record) => true
        },
        {
            title: 'Nationality',
            dataIndex: 'country',
            key: 'country',
            ellipsis: true,
            filteredValue: countryFilter,
            filters: countries.map((item, index) => ({ text: item.name, value: item.name })),
            onFilter: (value, record) => true
        },
        {
            title: 'Interest#1',
            dataIndex: 'interests[0].text',
            key: 'interest1',
            ellipsis: true,
            render: interest => (
                <span>{interest && interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'Interest#2',
            dataIndex: 'interests[1].text',
            key: 'interest2',
            ellipsis: true,
            render: interest => (
                <span>{interest && interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'Interest#3',
            dataIndex: 'interests[2].text',
            key: 'interest3',
            ellipsis: true,
            render: interest => (
                <span>{interest &&interest.split(' ')[0]}</span>
            )
        },
        {
            title: 'SignUp Date',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            width: '12%',
            sorter: (a, b) => 0,
            render: (text, record) => (
                <span>
                    {
                        record.created_at
                        
                        ?   moment(record.created_at).format('DD-MM-YY')

                        :   ''
                    }
                </span>
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, record) => (
                <Tag
                    color={record.disabled ? 'volcano' : 'green'}
                >
                    {record.disabled ? 'Frozen' : 'Active'}
                </Tag>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (text, record) => (
                <span>
                    <Button.Group>
                        <Tooltip title={'Edit Account'}>
                            <Button
                                icon='edit'
                                type='primary'
                                onClick={() => {
                                    setEditableUser(record)
                                    setEditModalVisible(true)
                                }}
                            />
                        </Tooltip>

                        <Popconfirm
                            title={
                                record.disabled
                                ?   "Are you sure you want to unfreeze this account?"
                                :   "Are you sure you want to freeze this account?"
                            }
                            onConfirm={() => {
                                if (record.disabled) {
                                    onOperation()
                                    unFreezeAccount(record.objectID, () => {
                                        refresh()
                                    })
                                } else {
                                    onOperation()
                                    freezeAccount(record.objectID, () => {
                                        refresh()
                                    })
                                }
                            }}
                            okText="Proceed"
                            cancelText="Cancel"
                        >
                            <Tooltip title={record.disabled ? 'Unfreeze Account' : 'Freeze Account'}>
                                <Button icon={record.disabled ? 'lock' : 'unlock'} type='dashed' ></Button>
                            </Tooltip>
                        </Popconfirm>

                        {
                            record.flag

                            ?   <Tooltip title={'Unflag Account'}>
                                    <Button
                                        style={{
                                            backgroundColor: '#FFBC0F',
                                            borderColor: '#FFBC0F'
                                        }}
                                        icon={'undo'}
                                        type='default'
                                        onClick={async () => {
                                            onOperation()
                                            await unFlagAccount(record.objectID)
                                            refresh()
                                        }}
                                    />
                                </Tooltip>
                            :   <Tooltip title={'Flag Account'}>
                                    <Dropdown
                                        overlay={
                                            <Menu
                                                onClick={async ({ key }) => {
                                                    onOperation()
                                                    if (key === '1') {
                                                        await flagAccount(record.objectID, 1)
                                                    } else {
                                                        await flagAccount(record.objectID, 2)
                                                    }
                                                    refresh()
                                                }}
                                            >
                                                <Menu.Item key='1'>
                                                    Report Inappropriate behavior
                                                </Menu.Item>
                                                <Menu.Item key='2'>
                                                    Report innacurate information
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        placement="bottomLeft"
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: '#FFBC0F',
                                                borderColor: '#FFBC0F'
                                            }}
                                            icon={record.flag ? 'undo' : 'flag'}
                                            type='default'
                                            disabled={true}
                                        />
                                    </Dropdown>
                                </Tooltip>
                        }

                        <Popconfirm
                            title="Are you sure you want to archive this account?"
                            onConfirm={() => {
                                onDelete()
                                deleteUser(record.objectID, () => {
                                    setTimeout(()=>{
                                        refresh()
                                    }, 2000)
                                })
                            }}
                            okText="Proceed"
                            cancelText="Cancel"
                        >
                            <Tooltip title={'Archive Account'}>
                                <Button icon='book' type='danger' ></Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </span>
            ),
        },
    ]

    return (
        <div >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 5
                }}
            >
                <ConnectedSearchBar />

                <Button
                    type='primary'
                    style={{
                        marginLeft: 5
                    }}
                    icon='reload'
                    onClick={instantRefresh}
                >
                    Refresh
                </Button>

                <Button
                    icon='filter'
                    style={{
                        marginLeft: 5
                    }}
                    onClick={() => {
                        setAfricanCountryFilter([])
                        setCountryFilter([])
                    }}
                >
                    Clear filters
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={hits}
                pagination={false}
                rowKey={'objectID'}
                onChange={(pagination, filters, sorter) => {
                    if (filters.african_country) {
                        if (filters.african_country.length === 0)
                            setAfricanCountryFilter([])
                        else
                            setAfricanCountryFilter(filters.african_country)
                    }

                    if (filters.country) {
                        if (filters.country.length === 0)
                            setCountryFilter([])
                        else
                            setCountryFilter(filters.country)
                    }

                    switch (sorter.order) {
                        case 'ascend':
                            setOrderByDate('users_date_asc')
                            break

                        case 'descend':
                            setOrderByDate('users_date_desc')
                            break
                    
                        default:
                            setOrderByDate('users')
                            break
                    }
                }}
            />

            <RefineByAfricanCountry
                defaultRefinement={africanCountryFilter}
                attribute={'african_country.value'}
                operator={'or'}
            />

            <RefineByCountry
                defaultRefinement={countryFilter}
                attribute={'country'}
                operator={'or'}
            />

            <SortByDate
                items={[
                    { value: 'users', label: 'Users' },
                    { value: 'users_date_desc', label: 'Latest' },
                    { value: 'users_date_asc', label: 'Oldest' }
                ]}
                defaultRefinement={orderByDate}
            />

            {
                editableUser

                    ? <EditDrawer
                        visible={editModalVisible}
                        onClose={() => {
                            setEditModalVisible(false)
                            setEditableUser(undefined)
                        }}
                        user={editableUser}
                        fetchData={(re) => {
                            console.log('')
                        }}
                        onEditDone={() => {
                            onEdit()
                        }}
                    />

                    : null
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        african_countries: state.africanCountries.countries,
        interests: state.interest.interests
    }
}

export default connect(mapStateToProps, null)(connectHits(Hits))