
import React from 'react'
import { Button, Modal, Form, Input, message, Upload, Icon } from 'antd'
import { addInterests, deleteInterests, getInterests, saveInterests } from '../actions/interests'
import { connect } from 'react-redux'

function AddInterest (props) {

    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState()
    const [imageUrl, setImageUrl] = React.useState()

    const handleSubmit = e => {
        e.preventDefault()

        const { form } = props

        form.validateFields((err, values) => {
            if (err) {
                console.log(err)
            } else {
                values.order = props.interests.length
                props.addInterests(values, () => {
                    props.getAllInterests()
                    message.success('added  with success\n')
                    setOpen(false)
                })
            }
        })
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            })
        }
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const { getFieldDecorator } = props.form

    return (
        <React.Fragment>
            <Button
                onClick={() => {
                    setOpen(true)
                }}
                type="primary"
            >
                Add interest
            </Button>

            <Modal
                title="Add interest"
                visible={open}
                footer={null}
                onCancel={() => {
                    setOpen(false)
                }}
            >
                <Form
                    onSubmit={handleSubmit.bind(this)}
                    className="login-form"
                >
                    <Form.Item label={'Title'}>
                        {
                            getFieldDecorator('text', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Title required'
                                    }
                                ]
                            })
                            (
                                <Input
                                    size='large'
                                    placeholder="Title"
                                />
                            )
                        }
                    </Form.Item>

                    <Form.Item label="Icon">
                        {
                            getFieldDecorator('image', {
                                valuePropName: 'file',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Icon required '
                                    }
                                ]
                            })
                            (
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {
                                        imageUrl

                                        ?   <img
                                                src={imageUrl}
                                                alt="avatar"
                                                style={{ width: '100%' }}
                                            />

                                        :   <div>
                                                <Icon type={'plus'} />
                                                <div className="ant-upload-text">
                                                    Upload
                                                </div>
                                            </div>
                                    }
                                </Upload>
                            )
                        }
                    </Form.Item>

                    <Button
                        block
                        size='large'
                        type="primary"
                        htmlType="submit"
                    >
                        Valider
                    </Button>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

function mapStateToProps (state) {
    return {
        interests: state.interest.interests
    }
}

function mapDispatchToProps (dispatch) {
    return {
        getAllInterests: () => {
            return dispatch(getInterests())
        },
        delete: (key, cb) => {
            return dispatch(deleteInterests(key, cb))
        },
        addInterests: (data, cb) => {
            return dispatch(addInterests(data, cb))
        },
        saveInterests: (items, cb) => {
            return dispatch(saveInterests(items, cb))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddInterest))