import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";

export const getRefs = () => async dispatch => {
    Backend.database().ref('refs').on('value', (refs) => {

        let all = []

        Object.keys(refs.val()).map(item => {
            all.push({id: item, value: refs.val()[item]})
        })

        return dispatch({type: types.GET_REFS, payload: all});
    })
}

