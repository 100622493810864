

import React, { useState, useEffect } from 'react'
import {
    connectHits,
    connectRefinementList,
    connectSearchBox,
    connectSortBy
} from 'react-instantsearch-dom'

import {
    Table,
    Button,
    Divider,
    Popconfirm,
    Popover,
    Tooltip,
    message,
    Tag,
    Input,
    Dropdown,
    Menu , Modal
} from 'antd'
import EdiText from 'react-editext'
import {addHardSkills, deleteHardSkills, getHardSkills, updateHardSkills} from "../actions/hardSkills";
import {connect} from "react-redux";
import {deleteUser, flagAccount, freezeAccount, unFlagAccount, unFreezeAccount} from "../api";
function SearchBar ({refine, currentRefinement}) {

    return (
        <Input.Search
            placeholder='Search by value '
            onSearch={(term) => refine(term)}
            enterButton
            allowClear
        />
    )
}

const ConnectedSearchBar = connectSearchBox(SearchBar)
function Hard_skills_hits({ hits, refresh , refine, instantRefresh ,addHardSkills  , getAllHardSkills , update  , deleteHard }) {
    const columns = [
        {
            title: 'value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            width : '10%',
            key: 'Actions',
            render: (text, record) => (
                <span>
                    <Button.Group>
                        <Tooltip title={'Edit hard Skills'}>
                            <Button
                                icon='edit'
                                type='primary'
                                onClick={() => {
                                    setCurrentHardSkill(record)

                                    setEditModalVisible(true)
                                }}
                            />



                        </Tooltip>

                        <Popconfirm
                            title={
                                  "Are you sure you want to delete this skills?"
                            }
                            onConfirm={()=>{deleteHard(record.objectID , ()=>{
                                refresh()

                                getAllHardSkills()
                                message.info('deleted')
                            })}}

                            okText="Proceed"
                            cancelText="Cancel"
                        >
                            <Tooltip title={ 'delete skills'}>
                                <Button icon={'delete'} type='dashed' ></Button>
                            </Tooltip>
                        </Popconfirm>

                    </Button.Group>
                </span>
            )
        },
    ];



    const [openModalAdd , setOpenModalAdd ] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [currentHardSkill, setCurrentHardSkill] = useState(undefined)

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [addModalVisible, setAddModalVisible] = useState(false)
    const [addModalLoading, setAddModalLoading] = useState(false)
    const [newHardSkillText, setNewHardSkillText] = useState('')




return(<div>

    <Modal
        title='Edit'
        visible={editModalVisible}
        onOk={() => {
            setConfirmLoading(true)

            update(currentHardSkill.objectID , currentHardSkill.value , () => {
                getAllHardSkills(() => {
                    refresh()
                    setConfirmLoading(false)
                    setEditModalVisible(false)
                })
            })
        }}
        confirmLoading={confirmLoading}
        onCancel={() => setEditModalVisible(false)}
    >
        <Input
            value={currentHardSkill ? currentHardSkill.value  : null}
            onChange={(e) => {
                setCurrentHardSkill({
                    ...currentHardSkill,
                    value: e.target.value
                })
            }}
        />
    </Modal>

    <div
        style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 5
        }}
    >
        <ConnectedSearchBar />

        <Button
            type='primary'
            style={{
                marginLeft: 5
            }}
            icon='reload'
            onClick={instantRefresh}
        >
            Refresh
        </Button>
            <Button
                icon={'book'}

                style={{marginLeft : 5 }}
                onClick={()=>{
                setOpenModalAdd(true)
            }} type="primary">Add HardSkills</Button>
            <Modal
                title="Add HardSkills"
                visible={openModalAdd}
                footer={null}
                onCancel={()=>{setOpenModalAdd(false)}}

            >
                <EdiText
                    viewContainerClassName='textEdit'
                    type="text"
                    saveButtonContent="Apply"
                    cancelButtonContent={<strong>Cancel</strong>}
                    editButtonContent="Edit"
                    value={''}
                    editing={openModalAdd}
                    onCancel={()=>{
                        setOpenModalAdd(false)
                    }}
                    onSave={(data)=>{
                        addHardSkills(data , ()=>{
                            getAllHardSkills()
                            setOpenModalAdd(false)
                            refresh()
                            message.success('added with success')
                        })
                    }}
                />
            </Modal>

    </div>
    <Table
        pagination={false}
        dataSource={hits} columns={columns}
        rowKey={'objectID'}
    />
</div>)



}

const mapDispatchToProps = (dispatch)=>{
    return {
        getAllHardSkills: (cb) => {
            return dispatch(getHardSkills(cb))
        },
        update : (key , data , cb)=>{
            return dispatch(updateHardSkills(key , data , cb))
        },
        deleteHard : (key ,  cb)=>{
            return dispatch(deleteHardSkills(key  , cb))
        },
        addHardSkills : ( data , cb)=>{
            return dispatch(addHardSkills(data  , cb))
        },


    }

}
export default connect(null ,mapDispatchToProps ) (connectHits(Hard_skills_hits))