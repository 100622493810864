
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Avatar, Button, Form, Icon, Input, List, message, Modal, Popconfirm, Upload} from 'antd';
import {
    addAfricanCountries,
    deleteAfricanCountries,
    getAfricanCountries,
    updateAfricanCountries
} from "../actions/africanCountries";
import EditCountryDrawer from "../components/EditCountryDrawer";
function AfricanCountries(props) {
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [loading, setLoading] = useState()

    const [imageUrl, setImageUrl] = useState()
    useEffect(() => {
        props.getAllAfricanCountries()
    }, [])

    const handleSubmit = e => {
        e.preventDefault()

        const {form} = props
        form.validateFields((err, values) => {


            if (err) {
                console.log(err)
            } else {

                props.addAfricanCountries(values, () => {

                    props.getAllAfricanCountries()
                    message.success('added  with success\n')
                    setOpenModalAdd(false)

                })
            }

        })
    }

    const {getFieldDecorator} = props.form;

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 5},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 12},
        },
    };

    const uploadButton = (
        <div>
            <Icon type={'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );


    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            })
        }
    };
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    return (
<div style={{backgroundColor : 'white' ,
padding : '15px'
}}>
        <List
            header={<div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor : 'white'
            }}>
                <Button onClick={() => {
                    setOpenModalAdd(true)
                }} type="primary">Add African Country</Button>
                <Modal
                    title="Add African Country"
                    visible={openModalAdd}
                    footer={null}
                    onCancel={() => {
                        setOpenModalAdd(false)
                    }}

                >
                    <Form onSubmit={handleSubmit.bind(this)} className="login-form">
                        <Form.Item label={'Title'}>
                            {getFieldDecorator('text', {
                                rules: [{required: true, message: 'Title required'}],
                                //  initialValue: props.configuration && props.configuration.signUpLink && props.configuration.signUpLink
                            })
                            (
                                <Input

                                    size='large'
                                    placeholder="Title"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="Icon">
                            {getFieldDecorator('image', {
                                valuePropName: 'file',
                                rules: [{required: true, message: 'Icon required '}],
                                // getValueFromEvent: normFile,
                            })(
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ?
                                        <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                                </Upload>,
                            )}
                        </Form.Item>
                        <Button
                            block

                            size='large'
                            type="primary"
                            htmlType="submit"
                        >
                            Valider
                        </Button>
                    </Form>
                </Modal>
            </div>}
            itemLayout="horizontal"
            dataSource={
                _.sortBy(props.africanCountries.countries, ['value'])
                }
            renderItem={item => (
                <List.Item

                    actions={[
                        <EditCountryDrawer country={item}/>,
                        <Popconfirm
                            title="Are you sure delete this ?"
                            onConfirm={() => {
                                props.delete(item.id, () => {
                                    props.getAllAfricanCountries()
                                    message.info('deleted')
                                })
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type={'dashed'}
                                icon={'delete'}
                                  style={{
                                      color: 'red',
                                      fontSize: 20
                                  }}
                            />
                        </Popconfirm>
                    ]}
                >
                    <List.Item.Meta
                        avatar={<Avatar src={item.flag}/>}

                        title={
                            item.value
                        }
                    />
                </List.Item>
            )}
        />
  </div>
    )


}

const mapStateToProps = (state)=>{
    return {
        africanCountries : state.africanCountries
    }

}
const mapDispatchToProps = (dispatch)=>{
    return {
        getAllAfricanCountries: ()=>{
            return dispatch(getAfricanCountries())
        },
        update : (key , data , cb)=>{
            return dispatch(updateAfricanCountries(key , data , cb))
        },
        delete : (key  , cb)=>{
            return dispatch(deleteAfricanCountries(key  , cb))
        },
        addAfricanCountries: ( data , cb)=>{
            return dispatch(addAfricanCountries(data  , cb))
        },

    }

}
export default connect(mapStateToProps ,mapDispatchToProps ) (Form.create()(AfricanCountries)

          )
