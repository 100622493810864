
import React from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import { Pagination } from 'antd'

function UserPagination ({
    currentRefinement,
    nbPages,
    refine,
    createURL
}) {
    return (
        <Pagination
            defaultCurrent={1}
            total={nbPages * 20}
            defaultPageSize={20}
            pageSize={20}
            current={currentRefinement}
            onChange={page => refine(page)}
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10
            }}
        />
    )
}

export default connectPagination(UserPagination)