import * as types from '../constants/Action_types'

import Backend from "../api/firebaseConfig";

export const signin = (formProps, callback) => async dispatch => {
    const {username, password} = formProps;
    let newUser
    try {
        const user = await Backend.auth().signInWithEmailAndPassword(
            username.toString(),
            password.toString()
        );
      await  Backend.database().ref('admins/' + user.user.uid).on('value', (snapshot) => {
            let newUser
            if (snapshot.val()) {
                newUser = snapshot.val()
                newUser.uid = user.user.uid
                dispatch({type: types.LOGIN, payload: {user: newUser, authenticated: true}});
                callback();
            }
        })
    } catch (error) {
        callback(error)
        dispatch({type: types.AUTH_ERROR, payload: error.message });

    }
};
export const updateUser = (formProps, callback) => async dispatch => {
    const {username, password, displayName, phoneNumber} = formProps;
    let newUser
    try {
        const verfi = await Backend.auth().signInWithEmailAndPassword(
            username.toString(),
            password.toString()
        );


        if (verfi) {
            const user = await Backend.auth().currentUser



            if (formProps.newPassword)
               await user.updatePassword(formProps.newPassword)

            if (formProps.newEmail)
              {  await user.updateEmail(formProps.newEmail)


                  Backend.database()
                      .ref('/admins/' + user.uid)
                      .update({
                          displayName: user.displayName,
                          phoneNumber: user.phoneNumber,
                          email: formProps.newEmail
                      })

                  dispatch({
                      type: types.LOGIN, payload: {
                          user: {
                              displayName: displayName,
                              phoneNumber: phoneNumber,
                              email: formProps.newEmail
                          }, authenticated: true
                      }
                  });
                  callback()

              }

            if (formProps.displayName || formProps.phoneNumber )
            user.updateProfile({
                displayName: displayName,
                phoneNumber: phoneNumber
            }).then(() => {

                Backend.database()
                    .ref('/admins/' + user.uid)
                    .update({
                        displayName: displayName,
                        phoneNumber: phoneNumber,
                        email: user.email
                    })
                dispatch({
                    type: types.LOGIN, payload: {
                        user: {
                            displayName: displayName,
                            phoneNumber: phoneNumber,
                            email: user.email
                        }, authenticated: true
                    }
                });
                callback()
            }).catch(function (error) {
                console.log(error)
            });
        }

    } catch (error) {

        dispatch({type: types.AUTH_ERROR, payload: error.message + '/code: ' + Date.now()});

    }
};
export const userLogout = () => {
    return {
        type: types.LOGOUT,
        payload: {}
    }
}
