import React, {useEffect, useState} from 'react'
import {Drawer, Row, Col, Icon, Button, Form, Input, Upload, message, DatePicker, InputNumber, Popconfirm, Divider, Spin} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {addInvitation, deleteInvitation, getInvitations, updateInvitation} from "../actions/invitations";
import {connect} from "react-redux";
import moment from "moment";
import EditInvitationDrawer from "../components/EditInvitationDrawer";
const expiredInvitation = (invitation)=> {
    if (moment(invitation.date).isBefore(moment(Date.now()))  || invitation.max <= 0  ){
        return true
    }else {
        return false
    }
}
function Invitation(props) {
    const [editDrawer, setEditDrawer] = useState(false)
    const [addDrawer, setAddDrawer] = useState(false)
    const [addLoading, setAddLoading] = useState(true)
    const {getFieldDecorator} = props.form;
    useEffect(() => {
        props.getAllInvitation(() => {
            setAddLoading(false)

        })
    }, [])
    const handleSubmit = e => {
        setAddLoading(true)
        e.preventDefault()
        const {form} = props
        form.validateFields((err, values) => {
            if (err) {

                setAddLoading(false)
            } else {
                values.date = values['date'].format('YYYY-MM-DD hh:mm:ss')
                props.addInvitation(values, () => {
                    message.success('added with success')
                    setAddDrawer(false)
                    props.getAllInvitation(() => {
                        setAddLoading(false)
                    })
                })
            }
        })
    }

    return (
        addLoading ? <Spin/> :
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Button style={{width: '10%', float: 'right', marginBottom: 5}}
                        type={'primary'}
                        onClick={() => {setAddDrawer(true)}}
                >Add Invitation</Button>
                <Drawer
                    width={'50%'}
                    title="Add Invitation"
                    placement={'right'}
                    closable={false}
                    onClose={() => {
                        setAddDrawer(false)
                    }}
                    visible={addDrawer}
                >
                    <Form onSubmit={handleSubmit.bind(this)} className="login-form">
                        <Form.Item label={'date'}>
                            {getFieldDecorator('date', {
                                rules: [{required: true, message: 'Date required'}],
                            })
                            (
                                <DatePicker showTime/>,
                            )}
                        </Form.Item>
                        <Form.Item label="Max">
                            {getFieldDecorator('max', {
                                rules: [{required: true, message: 'Max days required '}],
                            })(
                                <InputNumber min={1}/>,
                            )}
                        </Form.Item>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [{required: true, message: 'description is required '}],
                            })(
                                <Input
                                    type={'text'}
                                    placeholder={'description'}
                                />,
                            )}
                        </Form.Item>
                        <Button
                            block
                            disabled={addLoading}
                            size='large'
                            type="primary"
                            htmlType="submit"
                        >
                            Valider
                        </Button>
                    </Form>
                </Drawer>
                <div style={{padding: '15px'}}>
                    <Row
                        style={{backgroundColor: '#fafafa', padding: '15px'}}
                        gutter={[16, 16]}>
                        <Col span={4}><b>INDEX</b></Col>
                        <Col span={4}><b>description</b></Col>
                        <Col span={4}><b>DATE</b></Col>
                        <Col span={4}><b>MAX</b></Col>
                        <Col span={4}><b>URL</b></Col>
                    </Row>
                    {props.invitations.length > 0 && props.invitations.sort((a , b)=> expiredInvitation(a) - expiredInvitation(b) ).map((item, index) => {
                            return <Row
                                style={{backgroundColor: 'white', color : expiredInvitation(item) ? '#ffae42' : 'black'}}
                                key={index} gutter={[20, 20]}>
                                <Col span={4} >{index}</Col>
                                <Col span={4} >{item.description ? item.description : 'no data' }</Col>
                                <Col span={4}>{item.date}</Col>
                                <Col span={4}>{item.max}</Col>
                                <Col span={4} style={{width: '35%', marginLeft: '-10%'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div>
                                            {item && item.url && item.url}
                                            <CopyToClipboard
                                                text={item.url}
                                                onCopy={() => message.success('copied')}>
                                                <Button
                                                    type={'dashed'}
                                                    style={{marginLeft: 15}}
                                                    icon={'copy'}/>
                                            </CopyToClipboard>
                                        </div>
                                        <Button.Group>
                                            <EditInvitationDrawer item={item}/>
                                            <Popconfirm
                                                title="Are you sure delete this task?"
                                                onConfirm={() => {
                                                    setAddLoading(true)
                                                    props.delete(item.id, () => {
                                                        message.success('deleted with success')
                                                        props.getAllInvitation(() => {
                                                            setAddLoading(false)
                                                        })
                                                    })
                                                }}
                                                onCancel={() => {

                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type={'danger'} icon={'delete'}/>
                                            </Popconfirm>
                                        </Button.Group>
                                    </div>
                                </Col>
                                <Divider/>
                            </Row>
                        })
                    } </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        invitations: state.invitations.invitations
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllInvitation: (cb) => {
            return dispatch(getInvitations(cb))
        },
        update: (key, data, cb) => {
            return dispatch(updateInvitation(key, data, cb))
        },
        delete: (key, cb) => {
            return dispatch(deleteInvitation(key, cb))
        },
        addInvitation: (data, cb) => {
            return dispatch(addInvitation(data, cb))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Invitation))