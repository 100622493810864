import {Button, Drawer, Table} from "antd";
import React, {useState} from "react";
import moment from "moment";

function DetailsReport(props) {

    const [open , setOpen] = useState(false)

    const renderMsgColor = (flag) => {
        if (flag === 'abusive behavior') {
            return 'red'
        }

        if (flag === 'inaccurate information') {
            return '#FFBC0F'
        }

        return 'lightgrey'
    }

    const columns2 = [
        {
            dataIndex: 'why',
            key: 'why1',
            width: '7%',
            render: (item) => (
                <div
                    style={{
                        backgroundColor: renderMsgColor(item),
                        width: 10,
                        height: 10,
                        borderRadius: 5
                    }}
                />
            ),
        }, {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'name',
            dataIndex: 'who',
            key: 'who',
        }
        ,
        {
            title: 'Message',
            dataIndex: 'why',
            key: 'why',
        },
        {
            title: 'Created At',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (item) => (<div>
                {item && moment(item).format("DD-MM-YYYY")

                }
            </div>)
        }
    ]
    return (<div>

       <Button

           icon={'profile'}
           style={{cursor: 'pointer'}} onClick={()=>{setOpen(true)}}> Details
       </Button>
        <Drawer
            width={'50%'}
            title="Report Details"
            placement={'right'}
            closable={false}
            onClose={() => {
                setOpen(false)
            }}
            visible={open}
        >

            <Table dataSource={props.item} columns={columns2} pagination={false} rowKey={'id'}/>



        </Drawer>

    </div>)

}

export default DetailsReport