import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getConfiguration = () => async dispatch => {
    Backend.database().ref('/configuration').on('value', (snapshot) => {

        dispatch({type: types.GET_CONFIGURATION, payload: snapshot.val()});


    })
}
export const updateConfiguration = (values) => async dispatch => {

    if (values.image) {
        let uploadTask = Backend.storage().ref(`/logos/${Date.now()}`).put(values.image.file.originFileObj).then(
            res => {
                res.ref.getDownloadURL().then((downloadURL) => {

                    try {

                        let updates = {}
                        updates[`configuration/logo`] = downloadURL
                        if (values.faqLink)
                            updates[`configuration/faqLink`] = values.faqLink
                        if (values.signLink)
                            updates[`configuration/signUpLink`] = values.signLink


                        Backend.database().ref().update(updates).then(
                            () => {
                                dispatch({
                                    type: types.UPDATE_CONFIGURATION,
                                    payload: {logo: downloadURL, faqLink: values.faqLink, signUpLink: values.signLink}
                                });
                            }
                        );
                    } catch (error) {

                        console.log(error)
                    }
                })
            }
        )
    } else {

        try {

            // const gifKey = Backend.database().ref(`gifs/${user.uid}`).push()
            let updates = {}

            if (values && values.faqLink) {
                updates[`configuration/faqLink`] = values.faqLink
            }
            if (values.signLink) {
                updates[`configuration/signUpLink`] = values.signLink
            }

            Backend.database().ref().update(updates).then(
                () => {
                    dispatch({
                        type: types.UPDATE_CONFIGURATION,
                        payload: {faqLink: values.faqLink, signUpLink: values.signLink}
                    });
                }
            );
        } catch (error) {

            console.log(error)
        }


    }


}
