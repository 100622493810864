import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import {deleteReport, getReports} from "../actions/reports";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button, message, Popconfirm, Spin, Table, Tooltip} from 'antd';
import moment from "moment";
import DetailsReport from "../components/DetailsReport";
import {deleteFeedback} from "../actions/feedback";

function Reports(props) {

    const [loader, setLoader] = useState(true)

    const columns2 = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'name',
            dataIndex: 'who',
            key: 'who',
        }
        ,
        {
            title: 'Message',
            dataIndex: 'why',
            key: 'why',
        },
        {
            title: 'Created At',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (item) => (<div>
                {item && moment(item).format("DD-MM-YYYY")

                }
            </div>)
        }
    ]

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (item) => (
                <div>
                    {item}
                    <CopyToClipboard

                        text={item}
                        onCopy={() => message.success('copied')}>
                        <Tooltip title={'Copy'}>
                            <Button
                                style={{marginLeft: 15}}
                                type={'dashed'}
                                icon={'copy'}/>
                        </Tooltip>
                    </CopyToClipboard>
                </div>
            )
        },
        {
            title: 'reported By',
            dataIndex: 'reporters',
            key: 'reporters',
            render: (item) => (<div>

                {/*
                <Table dataSource={item} columns={columns2} pagination={false}/>
                */}

                <DetailsReport item={item}/>

            </div>)

        },
        {
            title: 'Actions',
            render : item => (
                <Popconfirm
                    title="Are you sure delete this task?"
                    onConfirm={()=>{
                        setLoader(true)
                        deleteReport(item.user , ()=>{
                            props.getReports(()=>{
                                setLoader(false)
                            })
                        })
                    }}
                    onCancel={()=>{console.log( 'annuler')}}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type={'danger'}
                        icon={'delete'}
                    />
                </Popconfirm>
            )
        }

    ];

    useEffect(() => {
        props.getReports(() => {
            setLoader(false)
        })


    }, [])

    return (
        <div style={{
            backgroundColor: 'white'
        }}>
            {loader ? <Spin/> :
                <Table dataSource={props.reports} columns={columns} rowKey={'user'}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {reports: state.reports.reports}
}
const mapDispatchToProps = (dispatch) => {
    return {getReports: (cb) => dispatch(getReports(cb))}

}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
