import axios from 'axios'
import Backend from "./firebaseConfig";
import * as types from "../constants/Action_types";


const uri = ''

const fetcher = axios.create({
    baseURL: uri,
    headers: {
        'Content-Type': 'application/json'
        // 'Authorization': localStorage.getItem('user') ? 'bearer ' + JSON.parse(localStorage.getItem('user')).token : undefined
        //'Authorization': undefined
    }
})

export const createDynamicLink = (invitationKey) => {

    return fetcher.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCNQspIL2RmVn1wtrDZcqwNyVvyXSTIINc`,
        {
            "dynamicLinkInfo": {
                "domainUriPrefix": "https://jokko.page.link",
                "link": "https://www.jokko.com/" + invitationKey,
/*

                "link": "https://play.google.com/store/apps/details?id=com.jokko&token=" + invitationKey,
*/

                "androidInfo": {
                    "androidPackageName": "com.jokko",
/*
                    "androidFallbackLink": "https://www.jokko.app/",
*/
                    "androidFallbackLink": "https://play.google.com/store/apps/details?id=com.jokko"
                },
                "socialMetaTagInfo": {
                    "socialTitle": "jokko",
                    "socialDescription": "jokko",
                    "socialImageLink": "https://firebasestorage.googleapis.com/v0/b/jokko-app.appspot.com/o/logos%2F1573068749410?alt=media&token=1652efd6-2349-4501-ab17-c72479d646ca"
                }
            }
        }
    ).then(res => res.data)

}


export const getUsers = (limit, lastKey) => {

    return fetcher.get(`https://europe-west1-jokko-app.cloudfunctions.net/users?size=${limit}&from=${lastKey}`).then(res => res.data)

}


export const updateUser = (id, values, cb) => {

    try {

        // const gifKey = Backend.database().ref(`gifs/${user.uid}`).push()
        let updates = {}

        updates[`users/${id}`] = values
        Backend.database().ref().update(updates).then(
            () => {
                cb()
            }
        ).catch(err => {

            cb(err)
        });
    } catch (error) {
        cb(error)
    }
}


export const getArchivedUsers = (cb) => async (dispatch) => {
    Backend.database().ref('archived_users').on('value', (users) => {

        let all = []
        users.val() &&
        Object.keys(users.val()).map(item => {
            all.push({uid: item, ...users.val()[item]})
        })
        cb()
        return dispatch({type: types.GET_USERS, payload: all});
    })
}


export const restoreUser = async (id, cb) => {

    try {

        const user = await Backend.database().ref(`archived_users/${id}`).once("value")

        await Backend.database().ref(`users/${id}`).set(user.val())
        await Backend.database().ref(`archived_users/${id}`).remove()

        await fetcher.get(`https://europe-west1-jokko-app.cloudfunctions.net/toggleaccountstatus?user_id=${id}&set_disabled=false`)

        cb()

        /*Backend.database().ref(`archived_users/${id}`).on("value", function (snapshot) {

            console.log(snapshot.val())

            if (snapshot.val())
                Backend.database().ref(`users/${id}`).set(snapshot.val()).then(() =>
                    Backend.database().ref(`archived_users/${id}`).remove().then(() => cb())
                )

        }, function (errorObject) {
            console.log("The read failed: " + errorObject.code);
            cb(errorObject)
        });*/

    } catch (error) {
        cb(error)
        console.log(error)
    }
}


export const deleteUser = async (id, cb) => {

    try {
        const user = await Backend.database().ref(`users/${id}`).once("value")

        await Backend.database().ref(`archived_users/${id}`).set(user.val())
        await Backend.database().ref(`users/${id}`).remove()
        await fetcher.get(`https://europe-west1-jokko-app.cloudfunctions.net/toggleaccountstatus?user_id=${id}&set_disabled=true`)
        cb()
        /*

        Backend.database().ref(`users/${id}`).on("value", function (snapshot) {

            console.log(snapshot.val());
            if (snapshot.val())
                Backend.database().ref(`archived_users/${id}`).set(snapshot.val()).then(
                    () => Backend.database().ref(`users/${id}`).remove().then(() => {
                        cb()
                    })
                )
        }, function (errorObject) {
            console.log("The read failed: " + errorObject.code);
            cb(errorObject)
        });*/
        /*    fetcher.get(`https://europe-west1-jokko-app.cloudfunctions.net/deleteuser?uid=${id}`).then(

            Backend.database().ref(`users/${id}`).remove().then(
                () => {
                    cb()
                }
            ).catch(err => {

                cb(err)
            })
        ).catch(err=>{
            console.log(err , 'err delete')
            })
    */

    } catch (error) {
        cb(error)
        console.log(error)
    }
}

export const deleteArchivedUser = async (id , cb )=>{
   // await Backend.database().ref(`archived_users/${id}`).remove()
    await fetcher.get('https://europe-west1-jokko-app.cloudfunctions.net/deleteuser?uid=' + id)
    cb()
}

export const freezeAccount = async (id, cb) => {
    await Backend.database().ref(`/users/${id}/disabled`).set(true)
    cb()
}

export const unFreezeAccount = async (id, cb) => {
    await Backend.database().ref(`/users/${id}/disabled`).set(false)
    cb()
}

export const getLanguages = () => {
    Backend.database().ref('languages').on('value', (snap) => {

        return snap.val()
    })
}

export const flagAccount = (uid, reason) => {
    return Backend.database().ref(`/users/${uid}/flag`).set(reason)
}

export const unFlagAccount = (uid) => {
    return Backend.database().ref(`/users/${uid}/flag`).set(null)
}
