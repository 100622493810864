import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";

import _ from 'lodash'


export const getHardSkills = (cb = () => {}) =>async dispatch => {

    Backend.database().ref('hard_skills').on('value' , (hard_skills)=>{

        let all = []

        Object.keys(hard_skills.val()).map(item=> {
            all.push({id : item , value :hard_skills.val()[item] })
        })

        let result = _.sortBy(all , [item => item.value.toUpperCase()])

        cb()

        return dispatch({type: types.GET_HARD_SKILLS, payload: result})
    } )
}

export const updateHardSkills = (key , data , cb   ) =>async dispatch => {
    let updates = {}
    updates[`hard_skills/${key}`] = data.toString()
    Backend.database().ref().update(updates).then(()=>{
        cb()
    })
}

export const deleteHardSkills = (key  , cb   ) =>async dispatch => {
    Backend.database().ref(`hard_skills/${key}`).remove().then(()=>{
        cb()
    })
}
export const addHardSkills = (data  , cb   ) =>async dispatch => {

    Backend.database().ref(`hard_skills`).push(data.toString()).then(()=>{
        cb()
    })
}
