import { combineReducers } from 'redux'

import auth from './auth'
import languages from './languages'
import africanCountries from './africanCountries'
import configuration from './configuration'
import hardSkills from './hardSkills'
import softSkills from './softSkills'
import interest from './interests'
import experience from './experience'
import emails from './emails'
import filter from './filters'
import refs from './refs'
import invitations from './invitations'
import feedback from './feedback'
import reports from './reports'
import users from './users'
const root =  combineReducers({
    auth: auth,
    configuration: configuration,
    languages : languages ,
    africanCountries : africanCountries,
    hard_skills : hardSkills,
    soft_skills: softSkills,
    interest: interest,
    experience: experience,
    email : emails,
    filter: filter,
    refs: refs,
    invitations: invitations,
    feedback: feedback,
    reports: reports,
    users: users,
})
export default root
