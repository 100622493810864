import React from 'react';
import logo from './logo.svg';
import './App.css';
import Login from "./pages/Login";
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import Home from "./pages/Home";
import 'antd/dist/antd.css'
import AllUsers from "./pages/AllUsers";

function App(props) {


    const PrivateRoute = ({component: Component, isAuthenticated, ...rest}) => {
        return (isAuthenticated ?
                <Route
                    {...rest}
                    render={props => {
                        return (
                            <Component {...props} />
                        )
                    }}
                /> :
                <Redirect to={'/login'}/>
        )
    }

    const isAuthenticated = props.user != null
    return (
        <Switch>
            <PrivateRoute path={'/'} exact component={Home} isAuthenticated={isAuthenticated}/>
            <Route path='/login' exact component={Login}/>
            <PrivateRoute path={'/home'} component={Home} isAuthenticated={isAuthenticated}/>
        </Switch>
    );
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: () => dispatch(userRequestLogout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
