import React, {Fragment, useState,} from 'react';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import {Checkbox} from '@atlaskit/checkbox';
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import logo from '../assets/images/logoJokko.png'
import {signin} from '../actions/auth'
import Form, {CheckboxField, ErrorMessage, Field, FormFooter, HelperMessage, ValidMessage,} from '@atlaskit/form';
import {message, Spin} from "antd";

function Login(props) {


    const [loading, setLoading] = useState(false)
    const submit = (data) => {
        setLoading(true)
        props.Login(data, (err) => {

            setLoading(false)
            if (err) {
                console.log(err)

                message.error( props.error ? props.error : 'invalid login or password ' )
            } else
                props.history.push('/home/users')

        })
    }

    return (
        <div
            style={{
                display: 'flex',
                width: '600px',
                maxWidth: '100%',
                margin: '5% auto',
                flexDirection: 'column',

            }}
        >
            <img src={logo} alt="logo jokko"
                 style={{
                     width: '50%',
                     height: 'auto',
                     marginLeft: '25%',
                 }}

            />

            <div style={{
                width: '50%',
                alignSelf: 'center'
            }}>
                <Form
                    onSubmit={(data) => {
                        submit(data)
                    }}

                >
                    {({formProps, submitting}) => (
                        <form {...formProps}>
                            <Field name="username" label="Email" isRequired defaultValue="">
                                {({fieldProps, error}) => (
                                    <Fragment>
                                        <TextField autoComplete="off" {...fieldProps} />
                                    </Fragment>
                                )}
                            </Field>
                            <Field
                                name="password"
                                label="Password"
                                defaultValue=""
                                isRequired
                                validate={value => (value.length < 8 ? 'TOO_SHORT' : undefined)}
                            >
                                {({fieldProps, error, valid}) => (
                                    <Fragment>
                                        <TextField type="password" {...fieldProps} />
                                        {!error && !valid && (
                                            <HelperMessage>
                                                Use 8 or more characters with a mix of letters, numbers &
                                                symbols.
                                            </HelperMessage>
                                        )}
                                        {error && (
                                            <ErrorMessage>
                                                Password needs to be more than 8 characters.
                                            </ErrorMessage>
                                        )}
                                        {valid && <ValidMessage></ValidMessage>}
                                    </Fragment>
                                )}
                            </Field>
                            <CheckboxField name="remember" label="Remember me" defaultIsChecked>
                                {({fieldProps}) => (
                                    <Checkbox {...fieldProps} label="Always sign in on this device"/>
                                )}
                            </CheckboxField>
                            <FormFooter style={{
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}>

                                <Button type="submit" appearance="primary" isLoading={submitting}>
                                    Login {loading ? <Spin/> : null}
                                </Button>

                            </FormFooter>
                        </form>
                    )}
                </Form>
            </div>
        </div>
    )


}


function mapStateToProps(state) {
    return {

        error: state.auth.error

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        Login: (formProps, callback) => {
            return dispatch(signin(formProps, callback))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))

