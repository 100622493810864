
import React, {useEffect, useState} from 'react'
import {Table, Divider, Tag, Spin, message, Icon, Button, Popconfirm} from 'antd';
import {useDispatch , useSelector , connect} from "react-redux";
import {GET_FEEDBACK} from "../constants/Action_types";
import {userLogout} from "../actions/auth";
import {deleteFeedback, getFeedback} from "../actions/feedback";
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment";

function FeedBack(props) {


    const [loader , setLoader] = useState(true)

    const feeds = useSelector(state => state.feedback.feedback)


    useEffect(()=>{
      props.getFeeds(()=>{
          setLoader(false)
      })
    },[])

    const columns = [
        {
            title: 'Author',
            dataIndex: 'author',
            width: '30%',
            key: 'author',
            render : (item)=>(
                <div>
                {item}
                <CopyToClipboard

                text={item}
                onCopy={() => message.success('copied')}>
                <Button
                    style={{marginLeft: 15 }}
                    icon={'copy'}
                    type={'dashed'}
                />
            </CopyToClipboard>
                </div>
                    )
        },
        {
            title: 'subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },{
            title: 'Created At',
            dataIndex: 'createdAt',
            width : '13%',
            sorter: (a, b) =>    moment(a.createdAt).isBefore(b.createdAt) ,
            key: 'createdAt',
            render : item => (
             item &&   moment(item).format('MM-DD-YYYY')
            )
        },{
            title: 'Actions',
         //   dataIndex: 'Actions',
            //key: 'Actions',
            render : item => (
               <Popconfirm
                   title="Are you sure delete this task?"
                   onConfirm={()=>{
                       setLoader(true)
                       deleteFeedback(item.key , ()=>{
                           props.getFeeds(()=>{
                               setLoader(false)
                           })
                       })
                   }}
                   onCancel={()=>{console.log( 'annuler')}}
                   okText="Yes"
                   cancelText="No"
               >
                 <Button
                 type={'danger'}
                 icon={'delete'}
                 />
             </Popconfirm>
            )
        },
    ];

    return (
        <div style={{backgroundColor: 'white'}}>
            {loader ? <Spin/> :
                <Table dataSource={feeds} columns={columns}/>
            }
        </div>
    )

}
const mapDispatchToProps = (dispatch) => {
    return {
        getFeeds: (cb) => {
            return dispatch(getFeedback(cb))
        }
    }
}
export default connect(null , mapDispatchToProps)( FeedBack)