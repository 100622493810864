import React, {useState} from 'react'
import {Button, DatePicker, Drawer, Form, Icon, Input, InputNumber, message} from "antd";
import moment from "moment";
import {addInvitation, deleteInvitation, getInvitations, updateInvitation} from "../actions/invitations";
import {connect} from "react-redux";


function EditInvitationDrawer(props) {
    const [editDrawer, setEditDrawer] = useState(false)
    const {getFieldDecorator} = props.form;

    const handleSubmit = e => {
        e.preventDefault()
        const {form} = props

        form.validateFields((err, values) => {
            if (err)
                console.log(err)
            else {
                values.date = values['date'].format('YYYY-MM-DD hh:mm:ss')
                props.update(props.item.id , values, () => {
                    message.success('updated with success')
                    setEditDrawer(false)
                    props.getAllInvitation(()=>{
                       // setEditDrawer(false)
                       // message.success('updated with success')
                    })
                })
            }
        })
    }
    return (

        <>
            <Button  type={'primary'} icon={'edit'} onClick={() => {
                setEditDrawer(true)
            }}/>


            <Drawer
                width={'50%'}
                title="Edit Invitation"
                placement={'right'}
                closable={false}
                onClose={() => {
                    setEditDrawer(false)
                }}
                visible={editDrawer}
            >
                <Form onSubmit={handleSubmit.bind(this)} className="login-form">
                    <Form.Item label={'date'}>
                        {getFieldDecorator('date', {
                            rules: [{required: true, message: 'Date required'}],
                            initialValue: moment(props.item.date, "YYYY-MM-DD hh:mm:ss")
                        })
                        (
                            <DatePicker showTime/>,
                        )}
                    </Form.Item>
                    <Form.Item label="Max">
                        {getFieldDecorator('max', {
                            rules: [{required: true, message: 'Max days required '}],
                            initialValue: props.item.max
                        })(
                            <InputNumber min={1}/>,
                        )}
                    </Form.Item>
                    <Form.Item label="Description">
                        {getFieldDecorator('description', {
                            rules: [{required: true, message: 'description is required '}],
                            initialValue: props.item.description ? props.item.description : 'No Data'
                        })(
                            <Input
                                type={'text'}
                                placeholder={'description'}
                            />,
                        )}
                    </Form.Item>

                    <Button
                        block

                        size='large'
                        type="primary"
                        htmlType="submit"
                    >
                        Valider
                    </Button>
                </Form>

            </Drawer>
        </>

    )


}


const mapDispatchToProps = (dispatch) => {
    return {
        getAllInvitation: (cb) => {
            return dispatch(getInvitations(cb))
        },
        update: (key, data, cb) => {
            return dispatch(updateInvitation(key, data, cb))
        },
        delete: (key, cb) => {
            return dispatch(deleteInvitation(key, cb))
        },
        addInvitation: (data, cb) => {
            return dispatch(addInvitation(data, cb))
        },


    }

}

export default connect(null, mapDispatchToProps) (Form.create()(EditInvitationDrawer))