import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {Button, Form, Icon, Input, message, Spin, Upload} from "antd";
import {getConfiguration, updateConfiguration} from "../actions/configuration";

function EditLogin(props) {
    const [imageUrl, setImageUrl] = useState()
    const [loading, setLoading] = useState()
    const [loadingAll, setLoadingAll] = useState(true)
    useEffect(() => {
      props.fetchConfiguration().then(()=>{
          setLoadingAll(false)
      })
        setImageUrl(props.configuration && props.configuration.logo)
    }, [])


    const handleSubmit = e => {
        e.preventDefault()

        const {form} = props
        form.validateFields((err, values) => {


            if (err) {
                console.log(err)
            } else {

                props.updateConfiguration(values).then(() => {
                    message.success('configuration changed with success\n')
                })
            }

        })
    }

    const {getFieldDecorator} = props.form;

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 5},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 12},
        },
    };

    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );


    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            })
        }
    };


    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    return (

        loadingAll ? <Spin/>
  :
        <div style={{width: '50%'}}>
            <h2>Edit Login</h2>
            <Form onSubmit={handleSubmit.bind(this)} className="login-form">
                <Form.Item label={'sign up Link'}>
                    {getFieldDecorator('signLink', {
                        rules: [{required: false, message: 'sign up Link required'}],
                        initialValue: props.configuration && props.configuration.signUpLink && props.configuration.signUpLink
                    })
                    (
                        <Input

                            size='large'
                            placeholder="Sign up link"
                        />,
                    )}
                </Form.Item>
                <Form.Item label={'FAQ link'}>
                    {getFieldDecorator('faqLink', {
                        rules: [{required: false, message: 'FAQ link required'}],
                        initialValue: props.configuration && props.configuration.faqLink && props.configuration.faqLink
                    })
                    (
                        <Input

                            size='large'
                            placeholder="FAQ link"
                        />,
                    )}
                </Form.Item>


                <Form.Item label="Logo">
                    {getFieldDecorator('image', {
                        valuePropName: 'file',
                        rules: [{required: false, message: 'logo required '}],
                        // getValueFromEvent: normFile,
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                        </Upload>,
                    )}
                </Form.Item>


                <Button
                    block

                    size='large'
                    type="primary"
                    htmlType="submit"
                >
                    Valider
                </Button>
            </Form>
        </div>
    )


}

function mapStateToProps(state) {
    return {

        user: state.auth.user.user,

        configuration: state.configuration.configuration
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchConfiguration: () => {
            return dispatch(getConfiguration())
        },

        updateConfiguration: (formProps) => {
            return dispatch(updateConfiguration(formProps))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(EditLogin)))
