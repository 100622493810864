import React, { useEffect, useState } from 'react'
import { Button, Collapse, DatePicker, Drawer, Form, Icon, Input, message, Popconfirm, Radio, Select } from 'antd';
import moment from 'moment'

import { connect } from "react-redux";
import { updateUser } from "../api";

const RadioGroup = Radio.Group;

function EditDrawer(props) {

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [other, setOther] = useState([])
	const { getFieldDecorator } = props.form;
	const { Panel } = Collapse;
	const { user } = props
	useEffect(() => {


		setOther(props.user.other)
	}, [])

	const handleSubmit = e => {
		setLoading(true)
		e.preventDefault()
		const { form } = props
		props.form.validateFields((err, values) => {
			if (err) {
				console.log(err)
				message.error(err)
				setLoading(false)
			} else {
				values.birthdate = values['birthdate'].format('YYYY-MM-DD')

				values.african_country = props.africanCountries.countries.find(a => a.value === values.african_country)
				values.langs = props.languages.filter(a => values.langs.includes(a.id))
				values.interests = props.interests.filter(a => values.interests.includes(a.id))

				values.domains = props.user.domains ? props.user.domains.filter(a => values.domains.includes(a.id)) : []
				values.tech_skills = props.hardSkills.filter(a => values.tech_skills.includes(a.id))
				

				for (let i = 0; i < values.tech_skills.length; i++) {
					if (props.user.tech_skills) {
						if (props.user.tech_skills.find(a => a.id === values.tech_skills[i].id).rating)
							values.tech_skills[i].rating = props.user.tech_skills.find(a => a.id === values.tech_skills[i].id).rating
					} else {
						values.tech_skills[i].rating = 0
					}
				}

				values.personal_attr = props.softSkills.filter(a => values.personal_attr.includes(a.id))

				for (let i = 0; i < values.personal_attr.length; i++) {
					if (props.user.personal_attr) {
						if (props.user.personal_attr.find(a => a.id === values.personal_attr[i].id).rating)
							values.personal_attr[i].rating = props.user.personal_attr.find(a => a.id === values.personal_attr[i].id).rating
					} else {
						values.personal_attr[i].rating = 0
					}
				}

				values.other = other ? other : []

				updateUser(props.user.objectID, values, (err) => {
					if (err) {
						message.error(err)
						setLoading(false)
					} else {
						//message.success('updated with success')
						//props.fetchData(true)
						setLoading(false)
						setVisible(false)
						props.onClose()
						props.onEditDone()
					}
				})
			}
		})
	}
	const { Option } = Select;

	return (
		<>
			<Drawer
				width={'50%'}
				title="Edit User"
				placement={'right'}
				closable={false}
				onClose={() => {
					props.onClose()
				}}
				visible={props.visible}
			>
				<Form onSubmit={handleSubmit.bind(this)} className="login-form">
					<Form.Item label={'First Name'}>
						{getFieldDecorator('firstName', {
							rules: [{ required: true, message: 'S\'il vous plaît entrer le nom de la catégorie' }],
							initialValue: user.firstName
						})
							(
								<Input
									size='large'
									placeholder="First Name"
								/>,
							)}
					</Form.Item>
					<Form.Item label={'Last Name'}>
						{getFieldDecorator('lastName', {
							rules: [{ required: true, message: 'S\'il vous plaît entrer le nom de la catégorie' }],
							initialValue: user.lastName
						})
							(
								<Input
									size='large'
									placeholder="Last Name"
								/>,
							)}
					</Form.Item>

					<Form.Item label={'Phone Number'}>
						{getFieldDecorator('phoneNumber', {
							rules: [{ required: true, message: 'Phone Number is required' }],
							initialValue: user.phoneNumber
						})
							(
								<Input
									size='large'
									placeholder="Phone Number"
								/>,
							)}
					</Form.Item>

					<Form.Item label={'Email'}>
						{getFieldDecorator('email', {
							rules: [{ required: false, message: 'S\'il vous plaît entrer le nom de la catégorie' }],
							initialValue: user.email
						})
							(
								<Input
									disabled
									size='large'
									placeholder="Email"
								/>,
							)}
					</Form.Item>
					<Form.Item label={'Total Up Votes'}>
						{getFieldDecorator('upvotes', {
							rules: [{ required: false}],
							initialValue: user.upvotes && Object.values(user.upvotes) && Object.values(user.upvotes).reduce((accumulator, currentValue) => accumulator + currentValue)
						})
						(
							<Input
								disabled
								size='large'
								placeholder="0"
							/>,
						)}
					</Form.Item>
					<Form.Item label={'refs'}>
						{getFieldDecorator('refs', {
							rules: [{ required: false}],
							initialValue: props.refs.find(x=> x.id === props.user.uid)  && Object.values(props.refs.find(x=> x.id === props.user.uid).value).length
						})
						(
							<Input
								disabled
								size='large'
								placeholder="0"
							/>,
						)}
					</Form.Item>

					<Form.Item label={'Bio'}>
						{getFieldDecorator('bio', {
							rules: [{ required: true, message: 'bio required' }],
							initialValue: user.bio
						})
							(
								<Input
									size='large'
									placeholder="bio"
								/>,
							)}
					</Form.Item>
					<Form.Item label={'City/Cities'}>
						{getFieldDecorator('city', {
							rules: [{ required: true, message: 'city required' }],
							initialValue: user.city
						})
							(
								<Input
									size='large'
									placeholder="City/Cities"
								/>,
							)}
					</Form.Item>
					<Form.Item label={'Languages'}>
						{getFieldDecorator('langs', {
							rules: [{ required: true, message: 'langs required' }],
							initialValue: user.langs ? user.langs.map(v => v.id) : undefined
						})
							(
								<Select
									mode="multiple"
									placeholder="Please select a language"
									style={{ width: '100%' }}
								>
									{props.languages.map((data) =>
										<Option key={data.id} value={data.id}>{data.value}</Option>
									)}
								</Select>,
							)}
					</Form.Item>


					<Form.Item label={'Hard Skills'}>
						{getFieldDecorator('tech_skills', {
							rules: [{ required: true, message: 'Hard Skills required' }],
							initialValue: user.tech_skills ? user.tech_skills.map(v => v.id) : undefined
						})
							(
								<Select
									mode="multiple"
									placeholder="Please select 4 hard skills"
									style={{ width: '100%' }}
								>
									{props.hardSkills.map((data) =>
										<Option key={data.id} value={data.id}>{data.value}</Option>
									)}
								</Select>,
							)}
					</Form.Item>

					<Form.Item label={'Soft Skills'}>
						{getFieldDecorator('personal_attr', {
							rules: [{ required: true, message: 'Soft Skills required' }],
							initialValue: user.personal_attr ? user.personal_attr.map(v => v.value) : undefined
						})
							(
								<Select
									mode="multiple"
									placeholder="Please select"
									style={{ width: '100%' }}
								>
									{props.softSkills.map((data) =>
										<Option key={data.id} value={data.id}>{data.value}</Option>
									)}
								</Select>,
							)}
					</Form.Item>

					<Form.Item label={'Interests'}>
						{getFieldDecorator('interests', {
							rules: [{ required: true, message: 'Interests are required' }],
							initialValue: user.interests ? user.interests.map(v => v.id) : undefined
						})
							(
								<Select
									mode="multiple"
									placeholder="Please select an interest"
									style={{ width: '100%' }}
								>
									{props.interests.map((data) =>
										<Option key={data.id} value={data.id}>{data.text}</Option>
									)}
								</Select>,
							)}
					</Form.Item>

					<Form.Item label={'Domains'}>
						{getFieldDecorator('domains', {
							rules: [{ required: false, message: 'Domains are required' }],
							initialValue: user.domains ? user.domains.map(v => v.id) : undefined
						})
							(
								<Select
									mode="multiple"
									placeholder="Please select a somain"
									style={{ width: '100%' }}
								>
									{user.domains && user.domains.map((data) =>
										<Option key={data.id} value={data.id}>{data.value}</Option>
									)}
								</Select>,
							)}
					</Form.Item>

					<Form.Item label={'Nationality'}>
						{
							getFieldDecorator('country', {
								rules: [{ required: true, message: 'Nationality is required' }],
								initialValue: user.country
							})
							(
								<Input
									size='large'
									placeholder="Nationality"
								/>,
							)
						}
					</Form.Item>
					
					<Form.Item label={'Country'}>
						{getFieldDecorator('african_country', {
							rules: [{ required: true, message: 'Country is required' }],
							initialValue: user.african_country && user.african_country.value
						})
							(
								<Select
									showSearch
									style={{ width: 200 }}
									placeholder="option"
								>
									{props.africanCountries.countries.map((data) => {

										return <Option key={data.value} value={data.value}>{data.value}</Option>

									})}

								</Select>

							,
							)}
					</Form.Item>
					<Form.Item label={'Birthdate'}>
						{getFieldDecorator('birthdate', {
							rules: [{ required: true, message: 'Birthdate is required' }],
							initialValue: moment(user.birthdate, "DD-MM-YYYY")
						})
							(
								<DatePicker showTime format="DD-MM-YYYY" />
							,
							)}
					</Form.Item>
					<Form.Item label={'Gender'}>
						{getFieldDecorator('gender', {
							rules: [{ required: true, message: 'Gender is required' }],
							initialValue: user.gender
						})
							(
								<Radio.Group>
									<Radio.Button value="Male">Male</Radio.Button>
									<Radio.Button value="Female">Female</Radio.Button>

								</Radio.Group>,
							)}
					</Form.Item>
					<Form.Item label={'Other'}>
						{getFieldDecorator('other', {
							rules: [{ required: false }],
							initialValue: user.other ? user.other : undefined
						})
							(
								<Collapse accordion>
									{other && other.map((data) =>
										<Panel header={data.type} key={data.id} value={data.id}
											extra={


												<Popconfirm
													title="Are you sure delete this task?"
													onConfirm={() => {
														setOther(other.filter(a => a.id !== data.id))

													}}
													okText="Yes"
													cancelText="No"
												>
													<Icon type={'delete'} />
												</Popconfirm>
											}>

											{
												data.type === 'FILE'
												?	<a href={data.value.uri} target={'_blank'}>{data.value.name}</a>
												:	<a href={data.value} target={'_blank'}> {data.value}</a>
											}
										</Panel>
									)}
								</Collapse>,
							)}
					</Form.Item>

					<Button
						block
						disabled={loading}
						size='large'
						type="primary"
						htmlType="submit"
					>
						Valider
					</Button>
				</Form>
			</Drawer>
		</>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

const mapStateToProps = (state) => {
	return {
		africanCountries: state.africanCountries,
		languages: state.languages.languages,
		interests: state.interest.interests,
		hardSkills: state.hard_skills.hard_skills,
		softSkills: state.soft_skills.soft_skills,
		refs : state.refs.refs
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditDrawer))