import Backend from "../api/firebaseConfig";
import * as types from "../constants/Action_types";


export const getLanguages = (cb) =>async (dispatch ) => {
     Backend.database().ref('languages').on('value' , (languages)=>{

         let all = []
         languages.val()&&
         Object.keys(languages.val()).map(item=> {
            all.push({id : item , value :languages.val()[item] })
         })
            cb()
         return dispatch({type: types.GET_LANGUAGES, payload: all});


    } )
}

export const updateLanguages = (key , data , cb   ) =>async dispatch => {
    let updates = {}
    updates[`languages/${key}`] = data.toString()
    Backend.database().ref().update(updates).then(()=>{
        cb()
    })
}

export const deleteLanguages = (key  , cb   ) =>async dispatch => {
    Backend.database().ref(`languages/${key}`).remove().then(()=>{
        cb()
    })
}
export const addLanguages = (data  , cb   ) =>async dispatch => {

    Backend.database().ref(`languages`).push(data.toString()).then(()=>{
        cb()
    })
}
