//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    soft_skills: [],

}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_SOFT_SKILLS:


            return {
                ...state,
                    soft_skills: action.payload
            }

        default:
            return state
    }
}

