import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router'
import {signin, userLogout} from "../actions/auth";
import React, {useEffect, useState} from 'react';
import {Layout, Menu, Icon, Button} from 'antd';
import EditLogin from "../components/EditLogin";
import {
    Switch,
    Route,

} from 'react-router-dom'
import Profile from "./Profile";
import AllUsers from "./AllUsers";
import AfricanCountries from "./AfricanCountries";
import Languages from "./Languages";
import HardSkills from "./HardSkills";
import SoftSkills from "./SoftSkills";
import Interests from "./Interests";
import Experience from "./Experience";
import EmailConfiguration from "./EmailConfiguration";
import Invitation from "./Invitation";
import FeedBack from "./Feedback";
import Reports from "./Reports";

import ArchivedUsers from "./ArchivedUsers";

function Home(props) {
    const {SubMenu} = Menu;


    const {Header, Sider, Content} = Layout;


    const [collapsed, setCollapsed] = useState(false);

    const [selectedKey, setSelectedKey] = useState();


    const toggle = () => {
        setCollapsed(!collapsed)
    };

    useEffect(() => {


        if (props.location.pathname.match('profile')) {

            setSelectedKey('profile')

        }
        if (props.location.pathname.match('edit_login')) {

            setSelectedKey('edit_login')

        }
        if (props.location.pathname.match('users')) {

            setSelectedKey('users')

        }
        if (props.location.pathname.match('languages')) {

            setSelectedKey('languages')

        }
        if (props.location.pathname.match('africanCountries')) {

            setSelectedKey('africanCountries')

        }
        if (props.location.pathname.match('hard_skills')) {

            setSelectedKey('hard_skills')

        }
        if (props.location.pathname.match('interests')) {

            setSelectedKey('interests')
        }
        if (props.location.pathname.match('experience')) {

            setSelectedKey('experience')
        }
        if (props.location.pathname.match('soft_skills')) {

            setSelectedKey('soft_skills')
        }
        if (props.location.pathname.match('feedback')) {

            setSelectedKey('feedback')
        }
        if (props.location.pathname.match('invitation')) {

            setSelectedKey('invitation')
        }
        if (props.location.pathname.match('reports')) {

            setSelectedKey('reports')
        }
        if (props.location.pathname.match('emails')) {

            setSelectedKey('emails')
        }
        if (props.location.pathname.match('archived_users')) {

                    setSelectedKey('archived_users')
                }


    }, [selectedKey]);


    return (
        <div style={{height: '100%'}}>
            <Layout style={{height: '-webkit-fill-available'}}>
                <Sider collapsible collapsed={collapsed} onCollapse={() => {
                    toggle()
                }}>
                    <div className="logo"/>
                    <Menu theme="dark" mode="inline"

                        // defaultOpenKeys={[selectedKey]}
                        // openKeys={[selectedKey]}
                          selectedKeys={[selectedKey]}
                        //defaultSelectedKeys={[selectedKey]}
                    >
                        <Menu.Item key="users" onClick={() => {
                            props.history.push('/home/users')
                        }}>
                            <Icon type="user"/>
                            <span>All Users</span>
                        </Menu.Item>
                        <Menu.Item key="reports" onClick={() => {
                            props.history.push('/home/reports')
                        }}>
                            <Icon type="user"/>
                            <span>Reports</span>
                        </Menu.Item>
                        <Menu.Item key="archived_users" onClick={() => {
                            props.history.push('/home/archived_users')
                        }}>
                            <Icon type="book"/>
                            <span>Archived users</span>
                        </Menu.Item>
                        <SubMenu
                            key="sub2"
                            title={
                                <span><Icon type="container"/><span>Taxonomies</span></span>}
                        >
                            <Menu.Item key="africanCountries"

                                       onClick={() => {
                                           props.history.push('/home/africanCountries')
                                       }}
                            >African Countries</Menu.Item>
                            <Menu.Item key="languages"
                                       onClick={() => {
                                           props.history.push('/home/languages')
                                       }}
                            >Languages</Menu.Item>
                            <Menu.Item key="hard_skills"
                                       onClick={() => {
                                           props.history.push('/home/hard_skills')
                                       }}
                            >Hard Skills</Menu.Item>
                            <Menu.Item key="soft_skills"
                                       onClick={() => {
                                           props.history.push('/home/soft_skills')
                                       }}
                            >Soft Skills</Menu.Item>
                            <Menu.Item key="interests"
                                       onClick={() => {
                                           props.history.push('/home/interests')
                                       }}
                            >Interests</Menu.Item>
                            <Menu.Item key="experience"
                                       onClick={() => {
                                           props.history.push('/home/experience')
                                       }}
                            >Experience</Menu.Item>

                        </SubMenu>
                        <SubMenu

                            key="sub3"
                            title={
                                <span><Icon type="control"/><span>Configuration</span></span>}
                        >
                            <Menu.Item key="profile" onClick={() => {

                                props.history.push('/home/profile')

                            }}>My Profile</Menu.Item>
                            <Menu.Item key="emails"
                                       onClick={() => {
                                           props.history.push('/home/emails')

                                       }}
                            >Emails</Menu.Item>
                            {/*  <Menu.Item key="13">Form</Menu.Item>*/}
                            <Menu.Item key="edit_login"


                                       onClick={() => {
                                           props.history.push('/home/edit_login')

                                       }}
                            >Login</Menu.Item>

                        </SubMenu>

                        <Menu.Item key="invitation"
                                   onClick={() => {
                                       props.history.push('/home/invitation')

                                   }}
                        >
                            <Icon type="share-alt"/>
                            <span>Invitations</span>
                        </Menu.Item>

                        <Menu.Item key="feedback"
                                   onClick={() => {
                                       props.history.push('/home/feedback')

                                   }}
                        >
                            <Icon type="book"/>
                            <span>Feedback</span>
                        </Menu.Item>

                        <Menu.Item key="20"
                                   onClick={() => {
                                       props.Logout();
                                       props.history.push('/login')

                                   }}
                                   style={{bottom: '10%', position: 'absolute'}}>
                            <Icon type="logout"/>
                            <span>Logout</span>
                        </Menu.Item>
                    </Menu>


                </Sider>
                <Layout>
                    <Header style={{
                        background: '#fff', padding: 0,
                        display: 'flex', flexDirection: 'row', alignItems: 'baseline'
                    }}>

                        <Icon
                            className="trigger"
                            type={collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={() => {
                                toggle()
                            }}
                        />  <h3 style={{marginLeft: '2%'}}>  {selectedKey}</h3>

                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            //background: '#fff',
                            minHeight: 280,
                            height: 'auto'
                        }}
                    >
                        <Switch>
                            <Route path={'/home/profile'} exact component={Profile}/>
                            <Route path={'/home/edit_login'} exact component={EditLogin}/>
                            <Route path={'/home/users'} exact component={AllUsers}/>
                            <Route path={'/home/africanCountries'} exact component={AfricanCountries}/>
                            <Route path={'/home/languages'} exact component={Languages}/>
                            <Route path={'/home/hard_skills'} exact component={HardSkills}/>
                            <Route path={'/home/soft_skills'} exact component={SoftSkills}/>
                            <Route path={'/home/interests'} exact component={Interests}/>
                            <Route path={'/home/experience'} exact component={Experience}/>
                            <Route path={'/home/emails'} exact component={EmailConfiguration}/>
                            <Route path={'/home/invitation'} exact component={Invitation}/>
                            <Route path={'/home/feedback'} exact component={FeedBack}/>
                            <Route path={'/home/reports'} exact component={Reports}/>
                            <Route path={'/home/archived_users'} exact component={ArchivedUsers} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )

}

function mapStateToProps(state) {
    return {

        user: state.auth.user
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        Logout: () => {
            return dispatch(userLogout())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
