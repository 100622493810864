import React, {useEffect, useState} from 'react'
import {Button, Drawer, Form, Input, Radio, DatePicker, message, Upload, Modal, Icon} from 'antd';
import moment from 'moment'

import {connect} from "react-redux";
import {updateUser} from "../api";
import {getAfricanCountries, updateAfricanCountries} from "../actions/africanCountries";

const RadioGroup = Radio.Group;

function EditCountryDrawer(props) {
    const [loading, setLoading] = useState()
    const [imageUrl, setImageUrl] = useState()

    const [visible, setVisible] = useState(false)
    const {getFieldDecorator} = props.form;

    useEffect(() => {
        setImageUrl(props.country.flag)
    }, [])
    useEffect(() => {
        setImageUrl(props.country.flag)
    }, [props.country])

    const uploadButton = (
        <div>
            <Icon type={'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            })
        }
    };


    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }


    const handleSubmit = e => {


        e.preventDefault()
        const {form} = props

        form.validateFields((err, values) => {


            if (err)
                console.log(err)
            else {


                props.update(props.country.id , values , ()=>{

                    props.getAllAfricanCountries()
                    setImageUrl(props.country.flag)
                    setVisible(false)
                    message.success('updated with success')
                })

            }
        })
    }

    return (

        <>
            <Button
                icon={'edit'}
                type={'primary'}
                key="list-loadmore-edit" onClick={() => {
                setVisible(true)
            }}></Button>


            <Drawer
                width={'50%'}
                title="Edit African Country"
                placement={'right'}
                closable={false}
                onClose={() => {
                    setVisible(false)
                }}
                visible={visible}
            >
                <Form onSubmit={handleSubmit.bind(this)} className="login-form">
                    <Form.Item label={'Title'}>
                        {getFieldDecorator('value', {
                            rules: [{required: false, message: 'Title required'}],
                            initialValue: props.country.value
                        })
                        (
                            <Input

                                size='large'
                                placeholder="Title"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="Icon">
                        {getFieldDecorator('image', {
                            valuePropName: 'file',
                            rules: [{required: false, message: 'Icon required '}],
                            // getValueFromEvent: normFile,
                        })(
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                            </Upload>,
                        )}
                    </Form.Item>


                    <Button
                        block

                        size='large'
                        type="primary"
                        htmlType="submit"
                    >
                        Valider
                    </Button>
                </Form>



            </Drawer>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {

    return {

        update : (key , values , cb) => {

            return dispatch(updateAfricanCountries(key , values , cb ))
        },
        getAllAfricanCountries: () => {
            return dispatch(getAfricanCountries())
        }

    }

}


const mapStateToProps = (state) => {
    return {}

}


export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditCountryDrawer))
