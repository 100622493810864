import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { updateUser } from '../actions/auth'
import { Icon, List, message, Popconfirm, Spin, Select, Button } from 'antd'
import reqwest from 'reqwest'
import { getSoftSkills } from '../actions/softSkills'
import { getInterests } from '../actions/interests'
import { getLanguages } from '../actions/languages'
import { getExperiences } from '../actions/experience'
import { getHardSkills } from '../actions/hardSkills'
import { getAfricanCountries } from '../actions/africanCountries'
import algoliasearch from 'algoliasearch'

import {
    connectInfiniteHits,
    InstantSearch,
    connectRefinementList,
    Configure,
    Pagination
} from 'react-instantsearch-dom'

import Hit from '../components/Hit'
import Hits from '../components/Hits'
import UserPagination from '../components/UserPagination'
import {getRefs} from "../actions/refs";

const { Option } = Select

const searchClient = algoliasearch('MO3W2OOX1D', '6c301a2a30fe657e6376ac2d4ee32112')
const index = searchClient.initIndex('users')

function AllUsers(props) {
    const size = 20
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [lastKey, setLastKey] = useState()
    const [editMode, setEditMode] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [searchState, setSearchState] = useState({ query: '' })

    const [shouldRefresh, setShouldRefresh] = useState(false)

    const [operationLoading, setOperationLoading] = useState(false)

    useEffect(() => {
        props.getAllData(()=>{

        })
    }, [])

    const forceRefresh = () => {
        setShouldRefresh(true)
        setTimeout(() => setShouldRefresh(false), 1000)
        message.info('Success')
        setOperationLoading(false)
    }

    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: 10
            }}
        >
            <Spin
                spinning={operationLoading}
                size='large'
            >
                <InstantSearch
                    indexName='users'
                    searchClient={searchClient}
                    searchState={searchState}
                    refresh={shouldRefresh}
                    onSearchStateChange={(nextSearchState) => {
                        setSearchState({ ...searchState, ...nextSearchState })
                    }}
                >   
                    <Hits
                        onDelete={() => setOperationLoading(true)}
                        onOperation={() => setOperationLoading(true)}
                        refresh={() => {
                            setTimeout(() => forceRefresh(), 4000)
                        }}
                        instantRefresh={() => forceRefresh()}
                        onEdit={() => {
                            setOperationLoading(true)
                            setTimeout(() => forceRefresh(), 4000)
                        }}
                    />
                    <UserPagination />
                </InstantSearch>
            </Spin>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user.user,
        users: state.users,
        interests: state.interest.interests,
        filters: state.filter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (formProps, callback) => {
            return dispatch(updateUser(formProps, callback))
        },
        getAllData: (cb) => {
            dispatch(getSoftSkills())
            dispatch(getInterests())
            dispatch(getLanguages(cb))
            dispatch(getExperiences())
            dispatch(getHardSkills())
            dispatch(getAfricanCountries())
            dispatch(getRefs())

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllUsers))