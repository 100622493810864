

import React, {useEffect, useState} from 'react'
import {addHardSkills, deleteHardSkills, getHardSkills, updateHardSkills} from "../actions/hardSkills";
import $ from "jquery";
import {connect} from "react-redux";
import {List, Avatar, Icon, Popconfirm, message, Button, Modal, Input, Spin} from 'antd';
import EdiText from 'react-editext'
import _ from 'lodash'
import { Link, DirectLink, Element, Events, animateScroll as scroll} from 'react-scroll'



import {
    connectInfiniteHits,
    InstantSearch,
    connectRefinementList,
    Configure,
    Pagination
} from 'react-instantsearch-dom'
import VirtualList from 'react-tiny-virtual-list'
import algoliasearch from "algoliasearch";
import Hard_skills_hits from "../components/hard_skills_hits";
import UserPagination from "../components/UserPagination";
import HardSkillsPagination from "../components/HardSkillsPagination";
const searchClient = algoliasearch('MO3W2OOX1D', '6c301a2a30fe657e6376ac2d4ee32112')
const index = searchClient.initIndex('hard_skills')

function HardSkills(props) {

    const [openModalAdd , setOpenModalAdd ] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [currentHardSkill, setCurrentHardSkill] = useState(undefined)

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [addModalVisible, setAddModalVisible] = useState(false)
    const [addModalLoading, setAddModalLoading] = useState(false)
    const [newHardSkillText, setNewHardSkillText] = useState('')
    const forceRefresh = () => {
        setShouldRefresh(true)
        setTimeout(() => setShouldRefresh(false), 2000)
        message.info('Success')
        setOperationLoading(false)
    }

    useEffect(()=>{
        props.getAllHardSkills(() => {})
    }, [])

   /* return (
        <React.Fragment>
            <div
                style={{
                    width: '100%',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    icon='plus'
                    type='primary'
                    onClick={() => {
                        setAddModalVisible(true)
                    }}
                >
                    Add a hard skill
                </Button>
            </div>

            <VirtualList
                width='100%'
                height={600}
                itemCount={props.hard_skills.length}
                itemSize={60} // Also supports variable heights (array or function getter)
                renderItem={({index, style}) =>
                    <div
                        key={props.hard_skills[index].id}
                        style={style}
                    >
                        <div
                            style={{
                                display: 'flex',
                                backgroundColor: 'white',
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderRadius: 4,
                                padding: 20
                            }}
                        >
                            <span>{props.hard_skills[index].value}</span>

                            <div>
                                <Button
                                    icon='edit'
                                    type='dashed'
                                    onClick={() => {
                                        setCurrentHardSkill(props.hard_skills[index])
                                        setEditModalVisible(true)
                                    }}
                                >

                                </Button>

                                <Popconfirm
                                    title='Are you sure delete this Hard Skill?'
                                    onConfirm={() => {
                                        props.delete(props.hard_skills[index].id , () => {
                                            props.getAllHardSkills(() => {
                                                message.success('Deleted')
                                            })
                                        })
                                    }}
                                    okText='Proceed'
                                    cancelText='Cancel'
                                >
                                    <Button
                                        icon='delete'
                                        type='danger'
                                        style={{
                                            marginLeft: 5
                                        }}
                                    >

                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal
                title='Edit'
                visible={editModalVisible}
                onOk={() => {
                    setConfirmLoading(true)

                    props.update(currentHardSkill.id , currentHardSkill.value , () => {
                        props.getAllHardSkills(() => {
                            setConfirmLoading(false)
                            setEditModalVisible(false)
                        })
                    })
                }}
                confirmLoading={confirmLoading}
                onCancel={() => setEditModalVisible(false)}
            >
                <Input
                    value={currentHardSkill ? currentHardSkill.value : ''}
                    onChange={(e) => {
                        setCurrentHardSkill({
                            ...currentHardSkill,
                            value: e.target.value
                        })
                    }}
                />
            </Modal>

            <Modal
                title='Add'
                visible={addModalVisible}
                onOk={() => {
                    setAddModalLoading(true)

                    props.addHardSkills(newHardSkillText, () => {
                        props.getAllHardSkills(() => {
                            setAddModalLoading(false)
                            setAddModalVisible(false)
                            setNewHardSkillText('')
                            message.success('added with success')
                        })
                    })
                }}
                confirmLoading={addModalLoading}
                onCancel={() => setAddModalVisible(false)}
            >
                <Input
                    value={newHardSkillText}
                    onChange={(e) => {
                        setNewHardSkillText(e.target.value)
                    }}
                />
            </Modal>
        </React.Fragment>
    )

    return(
        <List
            header={<div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'

            }}>
                <Button  onClick={()=>{
                    setOpenModalAdd(true)
                }} type="primary">Add HardSkills</Button>
                <Modal
                    title="Add HardSkills"
                    visible={openModalAdd}
                    footer={null}
                    onCancel={()=>{setOpenModalAdd(false)}}

                >
                    <EdiText
                        viewContainerClassName='textEdit'
                        type="text"
                        saveButtonContent="Apply"
                        cancelButtonContent={<strong>Cancel</strong>}
                        editButtonContent="Edit"
                        value={''}
                        editing={openModalAdd}
                        onCancel={()=>{
                            setOpenModalAdd(false)
                        }}
                        onSave={(data)=>{ console.log(data )
                            props.addHardSkills(data , ()=>{
                                props.getAllHardSkills()
                                setOpenModalAdd(false)
                                message.success('added with success')

                            })
                        }}
                    />

                </Modal>
            </div>}
            itemLayout="horizontal"
            dataSource={props.hard_skills.hard_skills}
            renderItem={item => (
                <List.Item

                    actions={[
                        <Popconfirm
                            title="Are you sure delete this ?"
                            onConfirm={()=>{props.delete(item.id , ()=>{
                                props.getAllHardSkills()
                                message.info('deleted')
                            })}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Icon type={'delete'}
                                  style={{
                                      color : 'red' ,
                                      fontSize : 20
                                  }}
                            />
                        </Popconfirm>
                    ]}
                >
                    <List.Item.Meta
                        title={
                            <EdiText
                                viewContainerClassName='textEdit'
                                type="textarea"
                                saveButtonContent="Apply"
                                cancelButtonContent={<strong>Cancel</strong>}
                                editButtonContent="Edit"
                                value={item.value}
                                onSave={(data)=>{ console.log(data , item.id)
                                    props.update(item.id , data , ()=>{

                                        props.getAllHardSkills()
                                    })

                                }}
                            />
                        }
                    />
                </List.Item>
            )}
        />
    )*/
    const size = 40
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [lastKey, setLastKey] = useState()
    const [editMode, setEditMode] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [searchState, setSearchState] = useState({ query: '' })

    const [shouldRefresh, setShouldRefresh] = useState(false)

    const [operationLoading, setOperationLoading] = useState(false)
   return  (
       <div
           style={{
               backgroundColor: 'white',
               padding: 10
           }}
       >
           <Spin
               spinning={operationLoading}
               size='large'
           >
               <InstantSearch
                   indexName='hard_skills'
                   searchClient={searchClient}
                   searchState={searchState}
                   refresh={shouldRefresh}
                   onSearchStateChange={(nextSearchState) => {
                       setSearchState({ ...searchState, ...nextSearchState })
                   }}
               >
                 <Hard_skills_hits
                       onDelete={() => setOperationLoading(true)}
                       onOperation={() => setOperationLoading(true)}
                       refresh={() => {
                           setTimeout(() => forceRefresh(), 4000)
                       }}
                       instantRefresh={() => forceRefresh()}
                       onEdit={() => {
                           setOperationLoading(true)
                           setTimeout(() => forceRefresh(), 4000)
                       }}
                   />
                    <HardSkillsPagination/>
               </InstantSearch>
           </Spin>


       </div>
   )


}

const mapStateToProps = (state)=>{
    return {
        hard_skills : state.hard_skills.hard_skills
    }

}
const mapDispatchToProps = (dispatch)=>{
    return {
        getAllHardSkills: (cb) => {
            return dispatch(getHardSkills(cb))
        },
        update : (key , data , cb)=>{
            return dispatch(updateHardSkills(key , data , cb))
        },
        delete : (key ,  cb)=>{
            return dispatch(deleteHardSkills(key  , cb))
        },
        addHardSkills : ( data , cb)=>{
            return dispatch(addHardSkills(data  , cb))
        },


    }

}


export default connect(mapStateToProps ,mapDispatchToProps ) (HardSkills)
