//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    experience: [],
}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_EXPERIENCE:
            return {
                ...state,
                experience: action.payload
            }
        default:
            return state
    }
}
