//import { Path } from '../routes'
import * as types from '../constants/Action_types'

const initialState = {
    countries: [],

}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_AFRICAN_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }

        default:
            return state
    }
}

