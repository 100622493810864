import * as types from "../constants/Action_types";

const initialState = {
    AFFilter: '',
    nationalityFilter: '',
    interestsFilter: '',
    reset: ''
}
export default function (state = initialState, action) {

    switch (action.type) {

        case types.AFRICAN_COUNTRIES_FILTER:
            return {
                ...state,
                AFFilter: action.payload
            }
        case types.INTERESTS_FILTER:
            return {
                ...state,
                interestsFilter: action.payload
            }
        case types.NATIONALITY_FILTER:
            return {
                ...state,
                nationalityFilter: action.payload
            }
        case types.RESET_FILTER:

            return {
                ...state,
                reset: action.payload
            }
        default:
            return state
    }
}
